import * as coreClient from "@azure/core-client";
import * as coreRestPipeline from "@azure/core-rest-pipeline";

export interface ProblemDetails {
  /** Describes unknown properties. The value of an unknown property can be of "any" type. */
  [property: string]: any;
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
}

export interface ApiKeyDto {
  id?: string;
  apiKey?: string;
  name?: string;
  created?: Date;
  createdBy?: string;
  active?: boolean;
  description?: string;
}

export interface ApiKeySettings {
  name: string;
  description?: string;
  active?: boolean;
}

export interface LoginAccountResource {
  status?: AccountStatus;
  subscriptionName?: string;
  subscriptionActive?: boolean;
  passwordInitRequired?: boolean;
  uid?: string;
  hint?: string;
}

export interface LoginResource {
  password?: string;
  uid?: string;
}

export interface CustomerLoginResponse {
  token?: string;
  forcePasswordUpdate?: boolean;
  subscription?: string;
  subscriptionGroup?: string;
}

export interface PasswordResetRequestResource {
  uid?: string;
  lang?: string;
}

export interface PasswordResetResource {
  pretoken?: string;
  token?: string;
  newPassword?: string;
}

export interface DocumentInfoResource {
  documentId?: string;
  lang?: string;
  documentType?: string;
  docUrl?: string;
  created?: Date;
  modified?: Date;
  published?: Date;
  rssDate?: Date;
  title?: string;
  identifier?: string;
  metadatas?: string;
}

export interface PeriodicalResource {
  title?: string;
  navIndex?: string;
  identifier?: string;
  docUrl?: string;
  /** Dictionary of <integer> */
  callNumbers?: { [propertyName: string]: number | null };
}

export interface BiblioDispoResult {
  serialHoldings?: SerialHolding[];
  dispoResults?: BiblioResults[];
}

export interface SerialHolding {
  libraryID?: string;
  text?: string;
}

export interface BiblioResults {
  catalogue?: string;
  qteEnCommande?: number;
  qteDisponible?: number;
  disponibilites?: Disponibilite[];
}

export interface Disponibilite {
  callNumber?: string;
  classificationID?: string;
  callID?: string;
  libraryID?: string;
  itemInfo?: ResultInfo[];
  holdable?: boolean;
}

export interface ResultInfo {
  currentLocationID?: string;
  itemTypeID?: string;
  dueDate?: Date;
}

export interface CaseLawsResourcePagedList {
  list?: CaseLawsResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface CaseLawsResource {
  identifier?: string;
  title?: string;
  published?: Date;
  publisher?: string;
  juridiction?: string;
  tribunal?: string;
  lang?: string;
  docUrl?: string;
  otherLangDocId?: string;
}

export interface PublicDocumentOptionResource {
  documentId?: string;
  docUrl?: string;
  title?: string;
  collection?: string;
  cover?: string;
  metadatas?: DocumentMetaField;
  published?: Date;
  modified?: Date;
}

export interface DocumentMetaField {
  description?: string;
  archived?: Date;
  pdfurl?: string;
  nomparties?: string;
  referenceneutre?: string;
  referenceneutrepertinence?: string;
  parallelreferences?: string;
}

export interface DocumentCollectionResource {
  id?: number;
  code?: string;
  nameFr?: string;
  nameEn?: string;
  descriptionFr?: string;
  descriptionEn?: string;
  note?: string;
  access?: AccessType;
  active?: boolean;
  documentTypes?: string[];
  subscriptions?: number[];
  group?: number[];
}

export interface DocumentCollectionResourceForCreate {
  id?: number;
  code: string;
  nameFr: string;
  nameEn: string;
  descriptionFr?: string;
  descriptionEn?: string;
  note?: string;
  access?: AccessType;
  active?: boolean;
  subscriptions?: number[];
  documentTypes?: string[];
  group?: number[];
}

export interface DocumentCollectionResourceForEdit {
  id?: number;
  code: string;
  nameFr: string;
  nameEn: string;
  descriptionFr?: string;
  descriptionEn?: string;
  note?: string;
  access?: AccessType;
  active?: boolean;
  subscriptions?: number[];
  documentTypes?: string[];
  group?: number[];
}

export interface DocumentCollectionsResourcePagedList {
  list?: DocumentCollectionsResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface DocumentCollectionsResource {
  id?: number;
  collectionCode?: string;
  partnerId?: number;
  title?: string;
  descriptionEn?: string;
  descriptionFr?: string;
  documentTypeId?: string;
  active?: boolean;
  documentType?: string;
  infobox?: string;
  copyrightZone?: string;
  accessCollectionCode?: string;
  docUrl?: string;
  documentCollectionsSort?: DocumentCollectionsSortResource;
}

export interface DocumentCollectionsSortResource {
  order1?: string;
  order2?: string;
  order3?: string;
  order4?: string;
}

export interface DocumentCollectionsResourceForCreate {
  id?: number;
  collectionCode: string;
  partnerId?: number;
  title: string;
  descriptionEn?: string;
  descriptionFr?: string;
  documentTypeId: string;
  active?: boolean;
  documentType?: string;
  infobox?: string;
  copyrightZone?: string;
  accessCollectionCode: string;
  documentCollectionsSort?: DocumentCollectionsSortResource;
}

export interface DocumentCollectionsResourceForEdit {
  id?: number;
  collectionCode: string;
  partnerId?: number;
  title: string;
  descriptionEn?: string;
  descriptionFr?: string;
  documentTypeId: string;
  active?: boolean;
  documentType?: string;
  infobox?: string;
  copyrightZone?: string;
  accessCollectionCode: string;
  documentCollectionsSort?: DocumentCollectionsSortResource;
}

export interface ClientSettingsDto {
  userId?: string;
  freeSearchesRemaining?: number;
  ordersCount?: number;
  searchPrice?: CracPriceTag[];
  profilePrice?: CracPriceTag[];
  taxes?: CracTax[];
  fees?: CracFee[];
}

export interface CracPriceTag {
  tag?: string;
  price?: number;
  debours?: number;
  fees?: string[];
}

export interface CracTax {
  name?: string;
  rate?: number;
}

export interface CracFee {
  name?: string;
  label?: string;
  amount?: number;
  type?: CracFeeType;
}

export interface SearchResultsDto {
  searchId?: string;
  totalResultsCount?: string;
  freeSearchCount?: number;
  error?: string;
  errors?: string[];
  results?: SearchResultDto[];
}

export interface SearchResultDto {
  name?: string;
  identifier?: string;
  jurisdiction?: string;
  jurisdictionType?: JurisdictionType;
  enterpriseType?: string;
  companyType?: string;
  registeredDate?: string;
  status?: string;
  active?: boolean;
  productsOffered?: CracProduct[];
}

export interface CracProduct {
  name?: string;
  type?: ProductType;
  price?: number;
}

export interface CracSearchResource {
  criteria?: SearchCriteria;
  paymentType?: PaymentType;
}

export interface SearchCriteria {
  inputType?: SearchInputType;
  jurisdictionType?: SearchJurisdictionType;
  inputText?: string;
}

export interface SendCracFileByEmailDto {
  mailFrom?: string;
  mailTo?: string;
  subject?: string;
  note?: string;
  sortedBy?: string;
  sortedDesc?: boolean;
  obj?: DocumentOrderedDto;
}

export interface DocumentOrderedDto {
  product?: ProductType;
  fileType?: "PDF";
  companyIdentifier?: string;
  companyName?: string;
  jurisdiction?: string;
  search?: SearchOrdered;
  documentId?: string;
  dateCreated?: string;
  dateReceived?: string;
  cost?: number;
  documentFile?: string;
}

export interface SearchOrdered {
  searchId?: string;
  searchText?: string;
  cost?: number;
}

export interface DocumentOrderedDtoPagedList {
  list?: DocumentOrderedDto[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface DocumentOrderResource {
  product?: ProductType;
  companyIdentifier?: string;
  companyName?: string;
  companyType?: string;
  jurisdiction?: JurisdictionType;
  paymentType?: PaymentType;
  searchId?: string;
}

export interface DocumentOrderResultDto {
  success?: boolean;
  fileAvailable?: boolean;
  documentId?: string;
  error?: string;
  ordersCount?: number;
}

export interface CustomerResourcePagedList {
  list?: CustomerResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface CustomerResource {
  id?: number;
  subscriptionId?: number;
  noCaij?: string;
  extIdentifier?: string;
  firstname?: string;
  lastname?: string;
  /** NOTE: This property will not be serialized. It can only be populated by the server. */
  readonly fullname?: string;
  email?: string;
  role?: CustomerRole;
  status?: AccountStatus;
  blocked?: boolean;
  phone?: string;
  birthdate?: Date;
  job?: string;
  organization?: string;
  card?: string;
  language?: Lang;
  phoneExt?: string;
  cellPhone?: string;
  logoUrl?: string;
  lcapCommercials?: boolean;
  lcapNewsletter?: boolean;
  lcapImprovement?: boolean;
  lcapLastEdit?: Date;
  uxpertiseId?: number;
  statusInfo?: string;
  groupCategory?: string;
  additionalInfo?: string;
  wfProfile?: string;
  wfLibrary?: string;
  emailVerified?: Date;
  activated?: Date;
  deactivated?: Date;
  deactivatedBy?: string;
  expiration?: Date;
  lastLogin?: Date;
  passwordUpdateRequired?: boolean;
  accessBetaCaij?: boolean;
  created?: Date;
  createdBy?: string;
  modified?: Date;
  modifiedBy?: string;
  inGroupSince?: Date;
  subscription?: SubscriptionDto;
  subscriptionGroup?: GroupDto;
  addresses?: CustomerAddressDto[];
  lawDomains?: LawPracticeFieldDto[];
  customerNotes?: CustomerNoteDto[];
  cardBalance?: number;
  libraryFees?: number;
  endorsed?: boolean;
  endorsedBy?: EndorsedCustomerResource;
  customersEndorsed?: EndorsedCustomerResource[];
  customerPendingChanges?: CustomerPendingChangeDto[];
}

export interface SubscriptionDto {
  id?: number;
  name?: string;
  contact?: ContactInfo;
  startDate?: Date;
  endDate?: Date;
  active?: boolean;
  sendLcapNotif?: boolean;
  doorCardProfile?: number;
  wfProfile?: string;
  uxpertiseGroup?: number;
  group?: SubscriptionGroupType;
  ownershipData?: DataOwnership;
  hasContent?: boolean;
  restrictedAccountCreation?: boolean;
  created?: Date;
  createdBy?: string;
  modified?: Date;
  modifiedBy?: string;
  note?: string;
  logoUrl?: string;
  databanks?: DatabankDto[];
  documentCollections?: DocumentCollectionDto[];
  products?: SubscriptionProductDto[];
  groups?: GroupDto[];
  subscriptionGroup?: GroupDto;
}

export interface ContactInfo {
  name?: string;
  email?: string;
  phone?: string;
  address?: AddressDto;
}

export interface AddressDto {
  id?: number;
  line1?: string;
  line2?: string;
  city?: string;
  zip?: string;
  state?: string;
  country?: string;
}

export interface DatabankDto {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  descriptionFr?: string;
  descriptionEn?: string;
  enabled?: boolean;
  logo?: string;
  accessInfoFr?: string;
  accessInfoEn?: string;
  unavailableMessage?: UnavailableMessageDto;
  appType?: DatabankAppType;
  databankStatus?: DatabankStatusOption;
  probe?: boolean;
  note?: string;
  bannerFr?: string;
  bannerEn?: string;
  disclaimer?: boolean;
  created?: Date;
  createdBy?: string;
  modified?: Date;
  modifiedBy?: string;
  databankAccesses?: DatabankAccessDto[];
  libraries?: LibraryDto[];
  subscriptions?: SubscriptionDto[];
  databankLawDomains?: DatabankLawDomainsDto[];
  databankContentTypes?: DatabankContentTypesDto[];
  group?: number[];
  access?: AccessUrl;
}

export interface UnavailableMessageDto {
  en?: string;
  fr?: string;
}

export interface DatabankAccessDto {
  databankId?: number;
  accessType?: DatabankAccessType;
  link?: string;
  linkType?: DatabankLinkType;
  ezproxyCollName?: string;
  proxyId?: number;
  proxy?: ProxyDto;
}

export interface ProxyDto {
  id?: number;
  name?: string;
  url?: string;
}

export interface LibraryDto {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  descriptionFr?: string;
  descriptionEn?: string;
  latitude?: number;
  longitude?: number;
  libraryTypeId?: number;
  cloakroom?: boolean;
  cloakroomInfo?: string;
  contactUs?: string;
  quoteFr?: string;
  quoteEn?: string;
  imgUrlFr?: string;
  imgUrlEn?: string;
  tempClosingMsgFr?: string;
  tempClosingMsgEn?: string;
  staffFr?: string;
  staffEn?: string;
  openHours?: string;
  enabled?: boolean;
  byodPrinterUrl?: string;
  created?: Date;
  createdBy?: string;
  modified?: Date;
  modifiedBy?: string;
  addressEn?: AddressDto;
  addressFr?: AddressDto;
  libraryType?: LibraryTypeDto;
  databanks?: DatabankDto[];
  libraryServices?: LibraryServiceLibraryOptionsDto[];
}

export interface LibraryTypeDto {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  code?: string;
  databanksVisible?: boolean;
}

export interface LibraryServiceLibraryOptionsDto {
  parameters?: string;
  service?: LibraryServiceDto;
}

export interface LibraryServiceDto {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  requireParameters?: boolean;
  detailsFr?: string;
  detailsEn?: string;
  libraryServiceLibraries?: LibraryServiceLibraryDto[];
}

export interface LibraryServiceLibraryDto {
  parameters?: string;
  libraryId?: number;
}

export interface DatabankLawDomainsDto {
  lawDomainId?: number;
}

export interface DatabankContentTypesDto {
  contentTypeCode?: string;
}

export interface AccessUrl {
  url?: string;
  urlRemote?: string;
}

export interface DocumentCollectionDto {
  id?: number;
  code?: string;
  nameFr?: string;
  nameEn?: string;
  descriptionFr?: string;
  descriptionEn?: string;
  note?: string;
  access?: AccessType;
  active?: boolean;
  subscriptions?: number[];
  collectionDocumentTypes?: CollectionDocumentTypesDto[];
  group?: number[];
}

export interface CollectionDocumentTypesDto {
  documentTypeCode?: string;
}

export interface SubscriptionProductDto {
  product?: ProductDto;
  parameter?: number;
}

export interface ProductDto {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  code?: string;
  descriptionFr?: string;
  descriptionEn?: string;
  access?: AccessType;
  active?: boolean;
  parametersRequired?: boolean;
  subscriptionGroupProducts?: SubscriptionGroupProductsDto[];
}

export interface SubscriptionGroupProductsDto {
  groupId?: number;
  productId?: number;
  parameter?: number;
}

export interface GroupDto {
  id?: number;
  name?: string;
  isDefault?: boolean;
  note?: string;
  groupType?: number;
  wfProfile?: string;
  accountRenewMode?: AccountRenewalMode;
  accountExpiryMode?: AccountExpiryType;
  accountRenewParameter?: number;
  emailTemplateId?: number;
  formUrl?: string;
  databanks?: DatabankDto[];
  documentCollections?: DocumentCollectionDto[];
  products?: SubscriptionProductDto[];
}

export interface CustomerAddressDto {
  name?: string;
  preferred?: boolean;
  imported?: boolean;
  id?: number;
  line1?: string;
  line2?: string;
  city?: string;
  zip?: string;
  state?: string;
  country?: string;
}

export interface LawPracticeFieldDto {
  id?: number;
  code?: string;
  nameFr?: string;
  nameEn?: string;
}

export interface CustomerNoteDto {
  id?: number;
  note?: string;
  pin?: boolean;
  employeeName?: string;
  createdBy?: string;
  created?: Date;
  updated?: Date;
  updatedBy?: string;
}

export interface EndorsedCustomerResource {
  id?: number;
  noCaij?: string;
  firstname?: string;
  lastname?: string;
  /** NOTE: This property will not be serialized. It can only be populated by the server. */
  readonly fullname?: string;
  email?: string;
  expiration?: Date;
  role?: CustomerRole;
  status?: AccountStatus;
}

export interface CustomerPendingChangeDto {
  id?: string;
  changeType?: PendingChangeType;
  pendingValue?: string;
  created?: Date;
  customer?: CustomerDto;
}

export interface CustomerDto {
  id?: number;
  noCaij?: string;
  extIdentifier?: string;
  firstname?: string;
  lastname?: string;
  /** NOTE: This property will not be serialized. It can only be populated by the server. */
  readonly fullname?: string;
  email?: string;
  role?: CustomerRole;
  status?: AccountStatus;
  statusInfo?: string;
  blocked?: boolean;
  groupCategory?: string;
  additionalInfo?: string;
  accessBetaCaij?: boolean;
  phone?: string;
  birthdate?: Date;
  job?: string;
  organization?: string;
  lcapCommercials?: boolean;
  lcapNewsletter?: boolean;
  lcapImprovement?: boolean;
  lcapLastEdit?: Date;
  card?: string;
  language?: Lang;
  phoneExt?: string;
  cellPhone?: string;
  logoUrl?: string;
  wfProfile?: string;
  wfLibrary?: string;
  wfPin?: string;
  emailVerified?: Date;
  activated?: Date;
  deactivated?: Date;
  deactivatedBy?: string;
  expiration?: Date;
  lastLogin?: Date;
  passwordUpdateRequired?: boolean;
  created?: Date;
  createdBy?: string;
  modified?: Date;
  modifiedBy?: string;
  inGroupSince?: Date;
  subscription?: SubscriptionDto;
  subscriptionGroup?: GroupDto;
  addresses?: CustomerAddressDto[];
  lawDomains?: LawPracticeFieldDto[];
  endorsedBy?: CustomerDto;
  endorsed?: boolean;
  customersEndorsed?: CustomerDto[];
  customerPendingChanges?: CustomerPendingChangeDto[];
  customerNotes?: CustomerNoteDto[];
  uxpertiseId?: number;
}

export interface CustomerResourceForCreate {
  id?: number;
  subscriptionId: number;
  subscriptionGroupId?: number;
  endorsedBy?: number;
  noCaij?: string;
  extIdentifier?: string;
  firstname: string;
  lastname: string;
  email: string;
  role: CustomerRole;
  status: AccountStatus;
  blocked?: boolean;
  phone?: string;
  birthdate?: Date;
  job?: string;
  organization?: string;
  lcapCommercials?: boolean;
  lcapNewsletter?: boolean;
  lcapImprovement?: boolean;
  accessBetaCaij?: boolean;
  language?: Lang;
  phoneExt?: string;
  cellPhone?: string;
  wfProfile?: string;
  wfLibrary?: string;
  expiration?: Date;
  addresses?: CustomerAddressDto[];
  lawDomains?: number[];
  notify?: boolean;
}

export interface CustomerResourceForEdit {
  id?: number;
  subscriptionId: number;
  subscriptionGroupId?: number;
  endorsedBy?: number;
  noCaij?: string;
  extIdentifier?: string;
  firstname: string;
  lastname: string;
  email: string;
  role: CustomerRole;
  status: AccountStatus;
  blocked?: boolean;
  phone?: string;
  birthdate?: Date;
  job?: string;
  organization?: string;
  lcapCommercials?: boolean;
  lcapNewsletter?: boolean;
  lcapImprovement?: boolean;
  language?: Lang;
  phoneExt?: string;
  cellPhone?: string;
  wfProfile?: string;
  wfLibrary?: string;
  additionalInfo?: string;
  accessBetaCaij?: boolean;
  expiration?: Date;
  modified?: Date;
  addresses?: CustomerAddressDto[];
  lawDomains?: number[];
}

export interface CustomerStatusDto {
  custModified?: Date;
  actionType?: string;
  expiration?: Date;
}

export interface CustomerHistoryResourcePagedList {
  list?: CustomerHistoryResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface CustomerHistoryResource {
  date?: Date;
  change?: string;
  by?: string;
  resourceName?: string;
  stateAfter?: string;
  stateBefore?: string;
  note?: string;
}

export interface CustomerLibraryAccountInfoResource {
  fees?: number;
  charges?: Charge[];
  holds?: Hold[];
  history?: ChargeHistory[];
  visits?: Entry[];
}

export interface Charge {
  checkoutDate?: Date;
  dueDate?: Date;
  renewals?: number;
  overdue?: boolean;
  callNumber?: string;
  title?: string;
  author?: string;
  itemID?: string;
  titleID?: string;
}

export interface Hold {
  callNumber?: string;
  title?: string;
  author?: string;
  itemID?: string;
  holdKey?: string;
  library?: string;
  libraryID?: string;
  queuePosition?: number;
}

export interface ChargeHistory {
  checkoutDate?: Date;
  checkinDate?: Date;
  callNumber?: string;
  title?: string;
  author?: string;
  itemID?: string;
  titleID?: string;
}

export interface Entry {
  access?: Date;
  library?: string;
}

export interface CardResource {
  card?: string;
  useNow?: boolean;
}

export interface CustomerCardTransactionsResource {
  prints?: Print[];
  transactions?: Transaction[];
}

export interface Print {
  created?: Date;
  id?: string;
  label?: string;
  status?: string;
  cost?: number;
  refunded?: boolean;
}

export interface Transaction {
  created?: Date;
  createdBy?: string;
  id?: string;
  label?: string;
  operation?: string;
  status?: string;
  amount?: number;
  refunded?: boolean;
}

export interface LibraryCardTransaction {
  transactionType?: CardTransactionType;
  amount?: number;
  reason?: string;
  comment?: string;
}

export interface InvoiceInfo {
  id?: number;
  description?: string;
  amount?: number;
  invoiceId?: string;
  success?: boolean;
  date?: Date;
  hasInvoiceFile?: boolean;
  refunded?: Date;
  refId?: string;
  cardType?: string;
  cardLastDigits?: string;
}

export interface InvoiceFileDto {
  invoiceId?: string;
  pdfFile?: string;
  htmlFile?: string;
}

export interface CustomerPendingChangeResource {
  id?: string;
  changeType?: PendingChangeType;
  pendingValue?: string;
  created?: Date;
  customer?: CustomerDto;
}

export interface EmailStatusDto {
  recipient?: string;
  subject?: string;
  status?: string;
  date?: Date;
  details?: string;
}

export interface CustomerNoteForCreate {
  note?: string;
  pin?: boolean;
}

export interface StatsDto {
  active?: number;
  activeOnLogin?: number;
  blocked?: number;
  groupName?: string;
  subscriptionId?: number;
  subscriptionName?: string;
  total?: number;
}

export interface AccountCreateInfo {
  identifier?: string;
  active?: boolean;
  expiration?: Date;
  subscriptionGroup?: number;
  firstname?: string;
  lastname?: string;
  email?: string;
  language?: string;
  address?: AddressDto;
  birthdate?: Date;
  phone?: string;
  phoneExtension?: string;
  cellPhone?: string;
  wfProfile?: string;
  wfLibrary?: string;
  organization?: string;
  job?: string;
  additionalInfo?: string;
  activeOnApproval?: boolean;
}

export interface ModeleMembreWs {
  id?: number;
  noCaij?: string;
  noBarreau?: string;
  prenom?: string;
  nom?: string;
  titre?: string;
  langue?: string;
  telephonePrincipal?: string;
  posteTelephonique?: string;
  telephoneSecondaire?: string;
  courriel?: string;
  adresse?: ModeleVueAdresse;
  birthday?: string;
  actif?: boolean;
  typeMembre?: string;
  bibliotheque?: string;
  noCarte?: string;
  abonnementActif?: AbonnementWs;
  abonnements?: AbonnementWs[];
  externalApps?: ModeleVueExternalApp[];
  mustChangePassword?: boolean;
  allowWebAccess?: boolean;
  allowMobileAppAccess?: boolean;
  allowLearning?: boolean;
  allowVisio?: boolean;
  allowProview?: boolean;
  collections?: string[];
  products?: string[];
  dateModification?: string;
  confirmed?: string;
  canRenewAccount?: boolean;
  pendingChanges?: ModeleVuePendingChange[];
}

export interface ModeleVueAdresse {
  adresse1?: string;
  rue?: string;
  appartement?: string;
  adresse2?: string;
  ville?: string;
  province?: string;
  codePostal?: string;
  pays?: string;
}

export interface AbonnementWs {
  fournisseur?: string;
  abonnement?: string;
  expiration?: string;
  cautionneur?: string;
}

export interface ModeleVueExternalApp {
  appType?: ExternalAppType;
  id?: string;
  synced?: boolean;
}

export interface ModeleVuePendingChange {
  id?: string;
  changeType?: number;
  pendingValue?: string;
  created?: string;
}

export interface CustomerBirthday {
  birthday?: string;
}

export interface OldSubscriptionInfo {
  userId?: string;
  active?: boolean;
  hasCard?: boolean;
  canRequestCard?: boolean;
  cardRequested?: boolean;
  hasBiblioAccess?: boolean;
  allowAddressEdit?: boolean;
  lexisNexisCCAAccess?: boolean;
  allowContentUploadUser?: boolean;
  allowWebAccess?: boolean;
  allowMobileAppAccess?: boolean;
  allowDico?: boolean;
  allowContentCDD?: boolean;
  allowDatabases?: boolean;
  allowLearning?: boolean;
  allowVisio?: boolean;
  allowProview?: boolean;
  allowRevueGestion?: boolean;
  allowRdv?: boolean;
  collections?: string[];
  products?: string[];
  subscriptions?: OldSubscription[];
}

export interface OldSubscription {
  subscriptionName?: string;
  providerName?: string;
  expiration?: string;
}

export interface Payment {
  authorisationNumber?: string;
  cardType?: string;
  bills?: Bill[];
}

export interface Bill {
  id?: string;
  amount?: number;
}

export interface SearchCustomerParams {
  lastname?: string;
  firstname?: string;
  noBarreau?: string;
  card?: string;
  email?: string;
  page?: number;
  pageSize?: number;
  onlyResearcher?: boolean;
}

export interface UserSearchData {
  id?: number;
  firstName?: string;
  lastName?: string;
  courriel?: string;
  courrielOther?: string;
  noUnicorn?: string;
  noCarte?: string;
  profil?: string;
  biblio?: string;
  noBarreau?: string;
}

export interface EmailSubscriptions {
  email?: string;
  subscribeCommercials?: number;
  subscribeNewsletter?: number;
  subscribeImprovement?: number;
  isMember?: boolean;
}

export interface FolderResource {
  name?: string;
  note?: string;
  parentId?: number;
}

export interface CustomerContentDto {
  type?: number;
  dateCreated?: Date;
  dateModified?: Date;
  parentFolder?: CustomerContentDto;
  title?: string;
  name?: string;
  note?: string;
  id?: number;
  dateArchived?: Date;
  items?: CustomerContentDtoPagedList;
  unikId?: string;
  url?: string;
  request?: string;
  application?: string;
  alertEnabled?: string;
  filters?: string;
  keywords?: string;
  filePath?: string;
}

export interface CustomerContentDtoPagedList {
  list?: CustomerContentDto[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface UserFoldersResourcePagedList {
  list?: UserFoldersResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface UserFoldersResource {
  id?: number;
  name?: string;
  note?: string;
  itemsCount?: number;
  alertItemsCountUnread?: number;
  archived?: boolean;
  dateCreated?: Date;
  dateModified?: Date;
  dateArchived?: Date;
}

export interface UserFoldersResourceForCreate {
  name?: string;
  note?: string;
}

export interface UserFoldersResourceForEdit {
  id?: number;
  name?: string;
  note?: string;
  archived?: boolean;
}

export interface UserContentItemsResourceForCreate {
  title?: string;
  url?: string;
  application?: string;
  note?: string;
  contentTypeId?: UserContentItemType;
  unikId?: string;
  documentType?: string;
  keywords?: string;
  filters?: string;
  alertJsonRequest?: string;
  path?: string;
  folderIds?: number[];
  collection?: string;
}

export interface UserContentItemsResource {
  id?: number;
  contentTypeId?: UserContentItemType;
  contentType?: ContentTypeDto;
  alertItemsCount?: number;
  alertItemsCountUnread?: number;
  alertJsonRequest?: string;
  alertEnabled?: boolean;
  title?: string;
  url?: string;
  note?: string;
  application?: string;
  folderId?: number;
  folder?: UserFoldersDto;
  unikId?: string;
  documentType?: string;
  documentTypeName?: string;
  keywords?: string;
  filters?: string;
  formattedFilters?: string;
  path?: string;
  dateCreated?: Date;
  dateModified?: Date;
  dateArchived?: Date;
}

export interface ContentTypeDto {
  id?: number;
  description?: string;
}

export interface UserFoldersDto {
  id?: number;
  name?: string;
  note?: string;
  itemsCount?: number;
  alertItemsCountUnread?: number;
  archived?: boolean;
  dateCreated?: Date;
  dateModified?: Date;
  dateArchived?: Date;
}

export interface SendFolderByEmailResource {
  note?: string;
  mailTo?: string;
  subject?: string;
  sortedBy?: string;
  sortedDesc?: boolean;
}

export interface UserContentItemsResourceForEdit {
  id?: number;
  title?: string;
  note?: string;
  userId?: string;
  alertEnabled?: boolean;
}

export interface RequestedDocumentFile {
  filename?: string;
  contentType?: string;
  data?: Uint8Array;
}

export interface DatabankResource {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  descriptionFr?: string;
  descriptionEn?: string;
  enabled?: boolean;
  logo?: string;
  accessInfoFr?: string;
  accessInfoEn?: string;
  unavailableMessage?: UnavailableMessageResource;
  appType?: DatabankAppType;
  databankStatus?: DatabankStatusOption;
  probe?: boolean;
  note?: string;
  bannerFr?: string;
  bannerEn?: string;
  disclaimer?: boolean;
  created?: Date;
  createdBy?: string;
  modified?: Date;
  modifiedBy?: string;
  databankAccesses?: DatabankAccessResource[];
  libraries?: LibraryItemResource[];
  subscriptions?: SubscriptionItemResource[];
  lawDomains?: number[];
  contentTypes?: string[];
  group?: number[];
}

export interface UnavailableMessageResource {
  fr?: string;
  en?: string;
}

export interface DatabankAccessResource {
  databankId?: number;
  accessType?: DatabankAccessType;
  link?: string;
  linkType?: DatabankLinkType;
  ezproxyCollName?: string;
  proxy?: ProxyDto;
}

export interface LibraryItemResource {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  enabled?: boolean;
  libraryType?: LibraryTypeDto;
}

export interface SubscriptionItemResource {
  id?: number;
  name?: string;
  startDate?: Date;
  endDate?: Date;
  active?: boolean;
}

export interface DatabankResourceForCreate {
  id?: number;
  nameFr: string;
  nameEn: string;
  descriptionFr?: string;
  descriptionEn?: string;
  accessInfoFr?: string;
  accessInfoEn?: string;
  enabled?: boolean;
  logo?: string;
  unavailableMessage?: UnavailableMessageResource;
  note?: string;
  bannerFr?: string;
  bannerEn?: string;
  databankStatus?: DatabankStatusOption;
  appType?: DatabankAppType;
  probe?: boolean;
  disclaimer?: boolean;
  databankAccesses?: DatabankAccessResource[];
  libraries?: number[];
  subscriptions?: number[];
  lawDomains?: number[];
  contentTypes?: string[];
  group?: number[];
}

export interface DatabankResourceForEdit {
  id?: number;
  nameFr: string;
  nameEn: string;
  descriptionFr?: string;
  descriptionEn?: string;
  accessInfoFr?: string;
  accessInfoEn?: string;
  enabled?: boolean;
  logo?: string;
  unavailableMessage?: UnavailableMessageResource;
  note?: string;
  bannerFr?: string;
  bannerEn?: string;
  disclaimer?: boolean;
  created?: Date;
  createdBy?: string;
  modified?: Date;
  modifiedBy?: string;
  databankStatus?: DatabankStatusOption;
  appType?: DatabankAppType;
  databankAccesses?: DatabankAccessResource[];
  probe?: boolean;
  libraries?: number[];
  subscriptions?: number[];
  lawDomains?: number[];
  contentTypes?: string[];
  group?: number[];
}

export interface DatabankWithProxyResource {
  url?: string;
  domains?: string[];
}

export interface MyDatabankResource {
  name?: string;
  description?: string;
  logo?: string;
  condition?: string;
  unavailableMessage?: string;
  appType?: DatabankAppType;
  databankStatus?: DatabankStatusOption;
  banner?: string;
  disclaimer?: string;
  exclusive?: string;
  buttonType?: string;
  libraries?: LibraryListResource;
  isNew?: boolean;
  created?: Date;
  onlineAccessInfo?: OnlineAccessLinkResource;
  contentTypes?: string[];
  domains?: string[];
}

export interface LibraryListResource {
  total?: number;
  libraryNameList?: string[];
}

export interface OnlineAccessLinkResource {
  link?: string;
  accessType?: DatabankAccessType;
  showOnlyInLibrary?: boolean;
}

export interface DictionaryResource {
  code?: string;
  collection?: string;
  lang?: string;
  title?: string;
  authors?: string;
  year?: number;
  callnumber?: string;
  created?: Date;
  published?: Date;
  publisher?: string;
  cover?: string;
  editorLink?: string;
  /** Dictionary of <string> */
  metadatas?: { [propertyName: string]: string | null };
}

export interface DictionaryPublication {
  publication?: DictionaryResource;
  /** Dictionary of <integer> */
  index?: { [propertyName: string]: number | null };
  home?: string;
}

export interface EmphasizedPubResource {
  id?: string;
  title?: string;
  isRestricted?: boolean;
  authors?: string[];
  partnerName?: string;
}

export interface LawDomainFieldDto {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  parentId?: number;
  parent?: LawDomainFieldDto;
}

export interface DoctrineDocumentTypeDto {
  id?: string;
  nameFr?: string;
  nameEn?: string;
}

export interface PublicationResourcePagedList {
  list?: PublicationResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface PublicationResource {
  id?: number;
  title?: string;
  documentType?: string;
  url?: string;
  partnerId?: string;
  partnerName?: string;
}

export interface PublicationNavCollectionResource {
  title?: string;
  customListTitle?: string;
  infobox?: string;
  copyrightZone?: string;
  logo?: string;
  hierarchy?: PublicationNavItemResource[];
}

export interface PublicationNavItemResource {
  itemUrl?: string;
  title?: string;
  cover?: string;
  descriptionFr?: string;
  descriptionEn?: string;
  authors?: string;
  published?: Date;
  edition?: string;
  lang?: string;
  otherLang?: string;
  children?: PublicationNavItemResource[];
}

export interface PublicationDetailsResource {
  parentTitle?: string;
  customListTitle?: string;
  logo?: string;
  authors?: string;
  cover?: string;
  published?: Date;
  descriptionFr?: string;
  descriptionEn?: string;
  infobox?: string;
  copyrightZone?: string;
  toc?: TocElement[];
  id?: number;
  title?: string;
  documentType?: string;
  url?: string;
  partnerId?: string;
  partnerName?: string;
}

export interface TocElement {
  title?: string;
  authors?: string[];
  file?: string;
  position?: string;
  childPosition?: number;
  children?: TocElement[];
}

export interface DocumentOptionResourcePagedList {
  list?: DocumentOptionResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface DocumentOptionResource {
  documentId?: string;
  docUrl?: string;
  title?: string;
  crawlerId?: number;
  collection?: string;
  cover?: string;
  metadatas?: DocumentMetaField;
  visible?: boolean;
  published?: Date;
  documentLocks?: DocumentLockResource;
  modified?: Date;
}

export interface DocumentLockResource {
  lockedBy?: string;
  lockedDate?: Date;
}

export interface DocumentResource {
  documentId?: string;
  collection?: string;
  lawId?: number;
  crawlerId?: number;
  documentStore?: string;
  documentStoreInfo?: string;
  lang?: string;
  publisher?: string;
  documentType?: string;
  created?: Date;
  modified?: Date;
  published?: Date;
  downloaded?: Date;
  rssDate?: Date;
  filepath?: string;
  cover?: string;
  title?: string;
  visible?: boolean;
  searchable?: boolean;
  identifier?: string;
  tribunal?: string;
  juridiction?: string;
  otherLangDocId?: string;
  metadatas?: string;
}

export interface DocumentResourceForEdit {
  documentId?: string;
  collection: string;
  lawId?: number;
  crawlerId?: number;
  documentStore?: string;
  documentStoreInfo?: string;
  lang: string;
  publisher: string;
  documentType: string;
  created?: Date;
  modified?: Date;
  published: Date;
  rssDate?: Date;
  filepath?: string;
  cover?: string;
  title: string;
  visible: boolean;
  searchable?: boolean;
  identifier?: string;
  tribunal?: string;
  juridiction?: string;
  otherLangDocId?: string;
  metadatas?: string;
}

export interface DocumentResourceForCreate {
  documentId?: string;
  collection: string;
  lawId?: number;
  crawlerId?: number;
  documentStore?: string;
  documentStoreInfo?: string;
  lang: string;
  publisher: string;
  documentType: string;
  created?: Date;
  modified?: Date;
  published?: Date;
  rssDate?: Date;
  content?: string;
  filepath?: string;
  cover?: string;
  title: string;
  visible: boolean;
  searchable?: boolean;
  identifier?: string;
  tribunal?: string;
  juridiction?: string;
  otherLangDocId?: string;
  metadatas?: string;
}

export interface DocumentInfo {
  documentType?: DocumentType;
  id?: string;
  publisherCode?: string;
  publisher?: PartnerDto;
  identifier?: string;
  title?: string;
  edition?: string;
  language?: string;
  otherLanguageDocUrl?: string;
  created?: string;
  date?: string;
  authors?: string[];
  citedByCount?: number;
  comments?: CaseComment[];
  annotatedLaws?: AnnotatedLaw[];
  tableOfContents?: boolean;
  parentDocId?: string;
  people?: PeopleDocument[];
  juridiction?: string;
  tribunal?: TribunalDocument;
  history?: CaseLawHistoryGrouping[];
  pdfUrl?: string;
  description?: string;
  lawReference?: string;
  archived?: Date;
  collection?: string;
  citedByArticle?: CitedDocumentByArticle[];
  lawDomainField?: LawDomainFieldDto[];
  raw?: string;
}

export interface PartnerDto {
  id?: number;
  code?: string;
  nameFr?: string;
  nameEn?: string;
  logoUrlFr?: string;
  logoUrlEn?: string;
  homeUrlFr?: string;
  homeUrlEn?: string;
  contentUrlFr?: string;
  contentUrlEn?: string;
  created?: Date;
  isShownOnHomePage?: boolean;
  isPinnedOnTop?: boolean;
  partnerDocumentTypes?: PartnerDocumentTypesDto[];
}

export interface PartnerDocumentTypesDto {
  id?: number;
  partnerId?: number;
  documentTypeCode?: string;
}

export interface CaseComment {
  id?: number;
  origin?: string;
  uri?: string;
  docUrl?: string;
  type?: string;
  title?: string;
  author?: string;
  reference?: string;
  cote?: string;
  description?: string;
  modificationDate?: Date;
  edition?: string;
  collection?: string;
}

export interface AnnotatedLaw {
  uri?: string;
  docUrl?: string;
  type?: string;
  title?: string;
  author?: string;
  cote?: string;
  modificationDate?: Date;
  edition?: string;
  collection?: string;
}

export interface PeopleDocument {
  name?: string;
  personType?: PersonType;
  collection?: string;
}

export interface TribunalDocument {
  codeFr?: string;
  codeEn?: string;
  labelFr?: string;
  labelEn?: string;
}

export interface CaseLawHistoryGrouping {
  court?: TribunalDto;
  entries?: CaseLawHistory[];
}

export interface TribunalDto {
  id?: number;
  codeFr?: string;
  codeEn?: string;
  labelFr?: string;
  labelEn?: string;
  juridiction?: string;
  tribunalType?: number;
  tribunalTypeObj?: TribunalTypeDto;
  coverage?: string;
  codes?: string[];
}

export interface TribunalTypeDto {
  id?: number;
  labelFr?: string;
  labelEn?: string;
}

export interface CaseLawHistory {
  id?: string;
  identifier?: string;
  title?: string;
  juridiction?: string;
  tribunal?: string;
  date?: string;
  docUrl?: string;
  docLang?: string;
}

export interface CitedDocumentByArticle {
  art?: string;
  count?: number;
  docs?: CitedDocument[];
  collection?: string;
}

export interface CitedDocument {
  id?: string;
  title?: string;
  date?: string;
  documentType?: DocumentType;
  authors?: string[];
  article?: string;
  jurisdiction?: string;
  tribunal?: string;
  identifier?: string;
  lang?: string;
  docUrl?: string;
  collection?: string;
}

export interface DoorCardProfileDto {
  id?: number;
  name?: string;
  activated?: boolean;
  deleted?: boolean;
}

export interface PublishDocumentResource {
  docContent?: string;
  created?: Date;
}

export interface DocumentVersionResource {
  created?: Date;
  createdBy?: string;
  filepath?: string;
  published?: Date;
}

export interface EmailTemplateDto {
  id?: number;
  code?: string;
  name?: string;
  note?: string;
  subjectFr?: string;
  subjectEn?: string;
  contentFr?: string;
  contentEn?: string;
}

export interface EmployeesResource {
  id?: number;
  identifier?: string;
  firstname?: string;
  lastname?: string;
  /** NOTE: This property will not be serialized. It can only be populated by the server. */
  readonly fullname?: string;
  email?: string;
  role?: EmployeeRole;
  title?: string;
  card?: string;
  active?: boolean;
  created?: Date;
  createdBy?: string;
  modified?: Date;
  modifiedBy?: string;
  resources?: EmployeeAccessDto[];
  libraries?: EmployeeLibraryDto[];
}

export interface EmployeeAccessDto {
  read?: boolean;
  add?: boolean;
  edit?: boolean;
  delete?: boolean;
  resource?: ResourceDto;
}

export interface ResourceDto {
  id?: number;
  code?: string;
  name?: string;
  description?: string;
}

export interface EmployeeLibraryDto {
  workingHoursEn?: string;
  workingHoursFr?: string;
  employee?: EmployeeDto;
  library?: LibraryDto;
}

export interface EmployeeDto {
  id?: number;
  identifier?: string;
  firstname?: string;
  lastname?: string;
  /** NOTE: This property will not be serialized. It can only be populated by the server. */
  readonly fullname?: string;
  email?: string;
  role?: EmployeeRole;
  title?: string;
  card?: string;
  active?: boolean;
  created?: Date;
  createdBy?: string;
  modified?: Date;
  modifiedBy?: string;
  resources?: EmployeeAccessDto[];
  libraries?: EmployeeLibraryDto[];
}

export interface EmployeeResourceForCreate {
  firstname: string;
  lastname: string;
  email: string;
  role?: EmployeeRole;
  title?: string;
  card?: string;
  active?: boolean;
  created?: Date;
  createdBy?: string;
  modified?: Date;
  modifiedBy?: string;
  resources?: EmployeeAccessDto[];
  groups?: number[];
}

export interface EmployeeResourceForEdit {
  id?: number;
  firstname: string;
  lastname: string;
  email: string;
  role?: EmployeeRole;
  title?: string;
  card?: string;
  active?: boolean;
  created?: Date;
  createdBy?: string;
  modified?: Date;
  modifiedBy?: string;
  resources?: EmployeeAccessDto[];
  groups?: number[];
}

export interface EmployeeUser {
  identifier?: string;
  role?: EmployeeRole;
  firstname?: string;
  lastname?: string;
  /** NOTE: This property will not be serialized. It can only be populated by the server. */
  readonly fullname?: string;
  email?: string;
  /** Dictionary of <EmployeeResourceAccess> */
  accesses?: { [propertyName: string]: EmployeeResourceAccess };
}

export interface EmployeeResourceAccess {
  ressourceCode?: string;
  read?: boolean;
  add?: boolean;
  edit?: boolean;
  delete?: boolean;
}

export interface LawResource {
  id?: number;
  code?: string;
  jurisdiction?: string;
  type?: string;
  titleFr?: string;
  titleEn?: string;
  created?: Date;
  modified?: Date;
  version?: Date;
  published?: boolean;
  abreviation?: string;
  annotated?: boolean;
  filterCaseLaw?: string;
  queryCaseLaw?: string;
  queryDoctrine?: string;
  lawReference?: string;
  lawReferenceEn?: string;
  lawHomePages?: LawHomePageDto[];
  lawIndexKeywords?: string[];
  lawDomain?: number[];
}

export interface LawHomePageDto {
  id?: string;
  lawId?: number;
  lang?: Lang;
  created?: Date;
  modified?: Date;
  page?: string;
}

export interface LawResourceForCreate {
  id?: number;
  code?: string;
  jurisdiction?: string;
  type?: string;
  titleFr?: string;
  titleEn?: string;
  created?: Date;
  modified?: Date;
  published?: boolean;
  abreviation?: string;
  annotated?: boolean;
  filterCaseLaw?: string;
  queryCaseLaw?: string;
  queryDoctrine?: string;
  lawReference?: string;
  lawReferenceEn?: string;
  lawIndexKeywords?: string[];
  lawDomain?: number[];
}

export interface LawResourceForEdit {
  id?: number;
  code?: string;
  jurisdiction?: string;
  type?: string;
  titleFr?: string;
  titleEn?: string;
  created?: Date;
  modified?: Date;
  published?: boolean;
  abreviation?: string;
  annotated?: boolean;
  filterCaseLaw?: string;
  queryCaseLaw?: string;
  queryDoctrine?: string;
  lawReference?: string;
  lawReferenceEn?: string;
  lawHomePages?: LawHomePageDto[];
  lawIndexKeywords?: string[];
  lawDomain?: number[];
}

export interface MyLawResource {
  code?: string;
  titleFr?: string;
  titleEn?: string;
  pageFr?: string;
  pageEn?: string;
  type?: string;
  jurisdiction?: string;
  annotated?: boolean;
  isNew?: boolean;
  toc?: TocElement[];
  filterCaseLaw?: string;
  queryCaseLaw?: string;
  queryDoctrine?: string;
  lawDomain?: LawDomainFieldDto[];
  lawReference?: string;
  version?: Date;
}

export interface MostViewedLawsResource {
  lawId?: number;
  code?: string;
  titleFr?: string;
  titleEn?: string;
  jurisdiction?: string;
  totalViews?: number;
}

export interface LawDomainFieldResourcePagedList {
  list?: LawDomainFieldResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface LawDomainFieldResource {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  parentId?: number;
}

export interface LawDomainFieldResourceForCreate {
  id?: number;
  nameFr: string;
  nameEn: string;
  parentId?: number;
}

export interface MyLawDomainFieldResource {
  id?: number;
  name?: string;
}

export interface LawDomainFieldResourceForEdit {
  id?: number;
  nameFr: string;
  nameEn: string;
  parentId?: number;
}

export interface LawPracticeFieldResource {
  id?: number;
  code?: string;
  nameFr?: string;
  nameEn?: string;
}

export interface LawPracticeFieldForCreate {
  id?: number;
  code: string;
  nameFr?: string;
  nameEn?: string;
}

export interface LawPracticeFieldForEdit {
  id?: number;
  code: string;
  nameFr?: string;
  nameEn?: string;
}

export interface UxpertiseDto {
  id?: number;
  name?: string;
  activated?: boolean;
  deleted?: boolean;
}

export interface LibraryResource {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  descriptionFr?: string;
  descriptionEn?: string;
  addressFr?: AddressDto;
  addressEn?: AddressDto;
  latitude?: number;
  longitude?: number;
  libraryTypeId?: number;
  cloakroom?: boolean;
  cloakroomInfo?: string;
  contactUs?: string;
  quoteFr?: string;
  quoteEn?: string;
  imgUrlFr?: string;
  imgUrlEn?: string;
  tempClosingMsgFr?: string;
  tempClosingMsgEn?: string;
  staffFr?: string;
  staffEn?: string;
  openHours?: string;
  enabled?: boolean;
  byodPrinterUrl?: string;
  created?: Date;
  createdBy?: string;
  modified?: Date;
  modifiedBy?: string;
  libraryType?: LibraryTypeResource;
  databanks?: DatabankItemResource[];
  libraryServices?: LibraryServiceLibraryOptionsDto[];
}

export interface LibraryTypeResource {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  code?: string;
  databanksVisible?: boolean;
}

export interface DatabankItemResource {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  enabled?: boolean;
  appType?: DatabankAppType;
  databankAccesses?: DatabankAccessDto[];
}

export interface LibraryResourceForCreate {
  id?: number;
  nameFr: string;
  nameEn: string;
  descriptionFr?: string;
  descriptionEn?: string;
  latitude?: number;
  longitude?: number;
  libraryTypeId?: number;
  cloakroom?: boolean;
  cloakroomInfo?: string;
  contactUs?: string;
  quoteFr?: string;
  quoteEn?: string;
  imgUrlFr?: string;
  imgUrlEn?: string;
  tempClosingMsgFr?: string;
  tempClosingMsgEn?: string;
  staffFr?: string;
  staffEn?: string;
  openHours?: string;
  enabled?: boolean;
  byodPrinterUrl?: string;
  addressEn?: AddressDto;
  addressFr?: AddressDto;
  databanks?: DatabankItemResource[];
  libraryServices?: LibraryServiceLibraryOptionsDto[];
}

export interface LibraryResourceForEdit {
  id?: number;
  nameFr: string;
  nameEn: string;
  descriptionFr?: string;
  descriptionEn?: string;
  latitude?: number;
  longitude?: number;
  libraryTypeId?: number;
  cloakroom?: boolean;
  cloakroomInfo?: string;
  contactUs?: string;
  quoteFr?: string;
  quoteEn?: string;
  imgUrlFr?: string;
  imgUrlEn?: string;
  tempClosingMsgFr?: string;
  tempClosingMsgEn?: string;
  staffFr?: string;
  staffEn?: string;
  openHours?: string;
  enabled?: boolean;
  byodPrinterUrl?: string;
  created?: Date;
  createdBy?: string;
  modified?: Date;
  modifiedBy?: string;
  addressEn?: AddressDto;
  addressFr?: AddressDto;
  databanks?: DatabankItemResource[];
  libraryServices?: LibraryServiceLibraryOptionsDto[];
}

export interface WordpressCaijLibraryRessource {
  id?: number;
  bib?: WordpressLibraryRessource[];
}

export interface WordpressLibraryRessource {
  actif?: boolean;
  byob?: string;
  citation?: string;
  closedMsg?: string;
  coords?: string;
  databases?: string[];
  hours?: string;
  image?: string;
  language?: string;
  lat?: string;
  longitude?: string;
  name?: string;
  personalizedText?: string;
  phone?: string;
  services?: WordpressLibraryServiceRessource[];
  staff?: string;
  type?: string;
  vestiaire?: boolean;
  vestiaireInfo?: string;
}

export interface WordpressLibraryServiceRessource {
  hoverText?: string;
  label?: string;
}

export interface MeResource {
  firstname?: string;
  lastname?: string;
  fullname?: string;
  email?: string;
  noCaij?: string;
  blocked?: boolean;
  role?: CustomerRole;
  phone?: string;
  birthdate?: Date;
  job?: string;
  organization?: string;
  card?: string;
  language?: Lang;
  phoneExt?: string;
  cellPhone?: string;
  logoUrl?: string;
  expiration?: Date;
  created?: Date;
  subscriptionName?: string;
  subscriptionGroupName?: string;
  accesses?: string[];
  collections?: string[];
  addresses?: CustomerAddressDto[];
  customerPendingChanges?: PendingChangeResource[];
  cardBalance?: number;
  picture?: string;
  modified?: Date;
  canRenew?: boolean;
  canSwitch?: boolean;
  emailVerified?: Date;
  lcapLastEdit?: Date;
  formUrl?: string;
  isEndorsed?: boolean;
  onboardingItems?: OnboardingItemResource[];
  databaseAccessesCount?: number;
}

export interface PendingChangeResource {
  id?: string;
  changeType?: PendingChangeType;
  pendingValue?: string;
  created?: Date;
}

export interface OnboardingItemResource {
  name?: string;
  completed?: boolean;
  data?: string;
}

export interface PersonalDetailsResource {
  firstname?: string;
  lastname?: string;
  birthdate?: Date;
  email?: string;
}

export interface ChangePasswordResource {
  currentPassword?: string;
  newPassword?: string;
}

export interface PhoneResource {
  phone?: string;
  phoneExt?: string;
  cellPhone?: string;
}

export interface PictureResponseSuccess {
  success?: boolean;
}

export interface MyCardTransactionsResource {
  transactionTypeOptions?: string[];
  data?: MeTransactionPagedList;
}

export interface MeTransactionPagedList {
  list?: MeTransaction[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface MeTransaction {
  date?: Date;
  transactionType?: string;
  amount?: number;
  notes?: string;
  myNote?: string;
  id?: number;
}

export interface NoteResource {
  targetObjectId?: string;
  message?: string;
}

export interface MeetingResource {
  lang?: string;
  size?: string;
}

export interface WhereByResponse {
  meetingId?: string;
  roomUrl?: string;
  startDate?: Date;
  endDate?: Date;
  hostRoomUrl?: string;
}

export interface MessageResource {
  id?: number;
  name?: string;
  visible?: boolean;
  rank?: number;
  startDate?: string;
  endDate?: string;
  closable?: boolean;
  placeholderId?: number;
  titleFr?: string;
  titleEn?: string;
  messageFr?: string;
  messageEn?: string;
  placeholder?: MessagePlaceholderResource;
}

export interface MessagePlaceholderResource {
  id?: number;
  code?: MessagePlaceholderType;
  name?: string;
  messages?: MessageResource[];
}

export interface MessageResourceForCreate {
  id?: number;
  name: string;
  visible?: boolean;
  rank: number;
  startDate: string;
  endDate: string;
  closable?: boolean;
  placeholderId: number;
  titleFr?: string;
  titleEn?: string;
  messageFr: string;
  messageEn: string;
  placeholder?: MessagePlaceholderResource;
}

export interface MessageResourceForEdit {
  id?: number;
  name: string;
  visible?: boolean;
  rank: number;
  startDate: string;
  endDate: string;
  closable?: boolean;
  placeholderId: number;
  titleFr?: string;
  titleEn?: string;
  messageFr: string;
  messageEn: string;
  placeholder?: MessagePlaceholderResource;
}

export interface MessageDto {
  id?: number;
  name?: string;
  visible?: boolean;
  rank?: number;
  startDate?: Date;
  endDate?: Date;
  closable?: boolean;
  titleFr?: string;
  titleEn?: string;
  messageFr?: string;
  messageEn?: string;
  placeholder?: PlaceholderDto;
}

export interface PlaceholderDto {
  id?: number;
  code?: string;
  name?: string;
  messages?: MessageDto[];
}

export interface ContentMostViewedResource {
  id?: number;
  title?: string;
  documentId?: number;
  secured?: boolean;
  rank?: number;
}

export interface UserHistoryResourcePagedList {
  list?: UserHistoryResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface UserHistoryResource {
  id?: number;
  historyTypeId?: number;
  title?: string;
  url?: string;
  application?: string;
  dateCreated?: Date;
}

export interface UserHistoryResourceForCreate {
  historyTypeId?: number;
  title?: string;
  url?: string;
  application?: string;
}

export interface FeesResponse {
  montantFrais?: number;
  frais?: LibraryBill[];
  hasOverdueCheckout?: boolean;
}

export interface LibraryBill {
  id?: string;
  reasonId?: string;
  description?: string;
  date?: Date;
  amount?: number;
  tps?: number;
  tvq?: number;
  totalAmount?: number;
}

export interface HoldRequestOld {
  libraryId?: string;
  requests?: HoldRequestByCallNumbers[];
}

export interface HoldRequestByCallNumbers {
  bibId?: number;
  callNumbers?: string[];
}

export interface HoldRequestResponseOld {
  success?: boolean;
  errors?: HoldRequestResponseError[];
}

export interface HoldRequestResponseError {
  id?: number;
  msg?: string;
  callNumber?: string;
}

export interface HoldRequest {
  callId?: string;
  libraryId?: string;
}

export interface LibraryCartItemDto {
  id?: number;
  unikId?: string;
  wfId?: string;
  customerId?: number;
  itemType?: CartItemType;
  processed?: boolean;
  title?: string;
  shownTitle?: string;
  authors?: string;
  callNumber?: string;
  dateModified?: Date;
  ouvrage?: string;
  numControl?: string;
  reference?: string;
  wfQuery?: string;
  cover?: string;
}

export interface ProcessCartParameters {
  lastFirstName?: string;
  email?: string;
  phone?: string;
  phoneExt?: string;
  libraryID?: string;
  fees?: string;
  note?: string;
  dispo?: string;
  type?: CartItemType;
  address?: AddressDto;
}

export interface LibraryCartProcessingResult {
  success?: boolean;
  errorMessages?: string[];
}

export interface LibraryPrinterResource {
  name?: string;
  byodPrinterUrl?: string;
}

export interface UserNotificationResourcePagedList {
  list?: UserNotificationResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface UserNotificationResource {
  id?: number;
  userId?: string;
  notificationTypeId?: number;
  contentItemId?: number;
  title?: string;
  note?: string;
  opened?: boolean;
  deleted?: boolean;
  dateCreated?: Date;
  dateDeleted?: Date;
  notificationType?: NotificationTypeResource;
}

export interface NotificationTypeResource {
  id?: number;
  description?: string;
}

export interface SimplePartnerResource {
  id?: number;
  code?: string;
  name?: string;
  logo?: string;
  isShownOnHomePage?: boolean;
  isPinnedOnTop?: boolean;
}

export interface PartnerResource {
  id?: number;
  code?: string;
  nameFr?: string;
  nameEn?: string;
  logoUrlFr?: string;
  logoUrlEn?: string;
  homeUrlFr?: string;
  homeUrlEn?: string;
  contentUrlFr?: string;
  contentUrlEn?: string;
  created?: Date;
  isShownOnHomePage?: boolean;
  isPinnedOnTop?: boolean;
  publications?: PublicationResource[];
  partnerDocumentTypes?: string[];
}

export interface PartnerResourceForCreate {
  id?: number;
  code: string;
  nameFr: string;
  nameEn?: string;
  logoUrlFr?: string;
  logoUrlEn?: string;
  homeUrlFr?: string;
  homeUrlEn?: string;
  contentUrlFr?: string;
  contentUrlEn?: string;
  created?: Date;
  isShownOnHomePage?: boolean;
  isPinnedOnTop?: boolean;
  partnerDocumentTypes?: string[];
}

export interface PartnerResourceForEdit {
  id?: number;
  code: string;
  nameFr: string;
  nameEn?: string;
  logoUrlFr?: string;
  logoUrlEn?: string;
  homeUrlFr?: string;
  homeUrlEn?: string;
  contentUrlFr?: string;
  contentUrlEn?: string;
  created?: Date;
  isShownOnHomePage?: boolean;
  isPinnedOnTop?: boolean;
  partnerDocumentTypes?: string[];
}

export interface MessagePlaceholderResourceForCreate {
  id?: number;
  code: string;
  name: string;
}

export interface MessagePlaceholderResourceForEdit {
  id?: number;
  code: string;
  name: string;
}

export interface ProductResource {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  code?: string;
  descriptionFr?: string;
  descriptionEn?: string;
  access?: AccessType;
  active?: boolean;
  parametersRequired?: boolean;
  subscriptions?: SubscriptionProductResource[];
  subscriptionGroupProducts?: SubscriptionGroupProductsResource[];
}

export interface SubscriptionProductResource {
  id?: number;
  parameter?: number;
}

export interface SubscriptionGroupProductsResource {
  groupId?: number;
  productId?: number;
  parameter?: number;
}

export interface ProductResourceForCreate {
  id?: number;
  nameFr: string;
  nameEn: string;
  code: string;
  descriptionFr?: string;
  descriptionEn?: string;
  access?: AccessType;
  active?: boolean;
  parametersRequired?: boolean;
  subscriptions?: SubscriptionProductResource[];
  subscriptionGroupProducts?: SubscriptionGroupProductsResource[];
}

export interface ProductResourceForEdit {
  id?: number;
  nameFr: string;
  nameEn: string;
  code: string;
  descriptionFr?: string;
  descriptionEn?: string;
  access?: AccessType;
  active?: boolean;
  parametersRequired?: boolean;
  subscriptions?: SubscriptionProductResource[];
  subscriptionGroupProducts?: SubscriptionGroupProductsResource[];
}

export interface ContentPublicationsResourcePagedList {
  list?: ContentPublicationsResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface ContentPublicationsResource {
  id?: number;
  code?: string;
  collection?: string;
  lang?: string;
  documentType?: string;
  contentTypeId?: number;
  title?: string;
  authors?: string;
  callnumber?: string;
  created?: Date;
  published?: Date;
  visible?: boolean;
  searchable?: boolean;
  isFrequentlyConsulted?: boolean;
  publisher?: string;
  cover?: string;
}

export interface ContentPublicationsResourceForEdit {
  id?: number;
  code: string;
  collection?: string;
  lang: string;
  documentType: string;
  contentTypeId?: number;
  title: string;
  authors?: string;
  callnumber?: string;
  published?: Date;
  visible?: boolean;
  searchable?: boolean;
  isFrequentlyConsulted?: boolean;
  publisher: string;
  cover?: string;
  created?: Date;
}

export interface ReperageResourcePagedList {
  list?: ReperageResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface ReperageResource {
  id?: number;
  name?: string;
  note?: string;
  itemsCount?: number;
  alertItemsCountUnread?: number;
  archived?: boolean;
  contentItems?: ContentItemResource[];
  dateCreated?: Date;
  dateModified?: Date;
  dateArchived?: Date;
  infos?: string;
  userId?: string;
  clientId?: string;
  clientLabel?: string;
  courriel?: string;
  createdByLabel?: string;
  status?: string;
  reason?: string;
  types?: string[];
  workers?: ReperageWorkerResource[];
}

export interface ContentItemResource {
  id?: number;
  contentTypeId?: UserContentItemType;
  contentType?: ContentTypeDto;
  alertItemsCount?: number;
  alertItemsCountUnread?: number;
  alertJsonRequest?: string;
  alertEnabled?: boolean;
  title?: string;
  url?: string;
  note?: string;
  application?: string;
  folderId?: number;
  userId?: string;
  dateCreated?: Date;
  dateModified?: Date;
  dateArchived?: Date;
  path?: string;
  fileContentType?: string;
}

export interface ReperageWorkerResource {
  id?: number;
  userId?: string;
  name?: string;
  library?: string;
  time?: number;
}

export interface ReperageResourceForCreate {
  name: string;
  note?: string;
  clientId: string;
  infos?: string;
  clientLabel: string;
  courriel: string;
  createdByLabel?: string;
  status?: string;
  reason?: string;
  types?: string[];
  workers?: ReperageWorkers[];
}

export interface ReperageWorkers {
  id?: number;
  folderId?: number;
  userId?: string;
  name?: string;
  library?: string;
  time?: number;
  folder?: UserReperageFolders;
}

export interface UserReperageFolders {
  folderId?: number;
  clientId?: string;
  infos?: string;
  clientLabel?: string;
  courriel?: string;
  createdByLabel?: string;
  status?: string;
  reason?: string;
  folder?: UserFolders;
  reperageType?: ReperageType[];
  reperageWorkers?: ReperageWorkers[];
}

export interface UserFolders {
  id?: number;
  name?: string;
  note?: string;
  userId?: string;
  archived?: boolean;
  dateCreated?: Date;
  dateModified?: Date;
  dateArchived?: Date;
  userFormationsFolders?: UserFormationsFolders;
  userReperageFolders?: UserReperageFolders;
  userContentItems?: UserContentItems[];
}

export interface UserFormationsFolders {
  folderId?: number;
  infos?: string;
  createdByLabel?: string;
  folder?: UserFolders;
  userFormationGroups?: UserFormationGroups[];
}

export interface UserFormationGroups {
  id?: number;
  label?: string;
  formationId?: number;
  dateSented?: Date;
  formation?: UserFormationsFolders;
  userFormationGroupUsers?: UserFormationGroupUsers[];
}

export interface UserFormationGroupUsers {
  groupId?: number;
  userId?: string;
  userLabel?: string;
  courriel?: string;
  dateSent?: Date;
  group?: UserFormationGroups;
}

export interface UserContentItems {
  id?: number;
  contentTypeId?: number;
  title?: string;
  url?: string;
  note?: string;
  application?: string;
  folderId?: number;
  userId?: string;
  archived?: boolean;
  dateCreated?: Date;
  dateModified?: Date;
  dateArchived?: Date;
  alertJsonRequest?: string;
  alertEnabled?: boolean;
  contentType?: ConfigContentTypes;
  folder?: UserFolders;
  userContentItemsDocuments?: UserContentItemsDocuments;
  userContentItemsFiles?: UserContentItemsFiles;
  userContentItemsQueries?: UserContentItemsQueries;
  userAlertItems?: UserAlertItems[];
}

export interface ConfigContentTypes {
  id?: number;
  description?: string;
  userContentItems?: UserContentItems[];
}

export interface UserContentItemsDocuments {
  id?: number;
  unikId?: string;
  documentType?: string;
  idNavigation?: UserContentItems;
}

export interface UserContentItemsFiles {
  id?: number;
  path?: string;
  contentType?: string;
  idNavigation?: UserContentItems;
}

export interface UserContentItemsQueries {
  id?: number;
  keywords?: string;
  filters?: string;
  idNavigation?: UserContentItems;
}

export interface UserAlertItems {
  id?: number;
  contentItemId?: number;
  title?: string;
  url?: string;
  unikId?: string;
  documentType?: string;
  opened?: boolean;
  dateCreated?: Date;
  contentItem?: UserContentItems;
}

export interface ReperageType {
  folderId?: number;
  type?: string;
  folder?: UserReperageFolders;
}

export interface ReperageResourceForEdit {
  id?: number;
  name: string;
  note?: string;
  clientId: string;
  infos?: string;
  clientLabel: string;
  courriel: string;
  createdByLabel?: string;
  status?: string;
  reason?: string;
  types?: string[];
  workers?: ReperageWorkers[];
}

export interface Resource {
  id?: number;
  name?: string;
  code?: string;
  description?: string;
}

export interface ResourceForCreate {
  id?: number;
  name: string;
  code: string;
  description?: string;
}

export interface ResourceForEdit {
  id?: number;
  name: string;
  code: string;
  description?: string;
}

export interface SearchResults {
  search?: BasicQueryParameters;
  totalCount?: number;
  duration?: number;
  errors?: string[];
  /** Anything */
  termsToHighlight?: any;
  /** Anything */
  phrasesToHighlight?: any;
  queryCorrections?: CoveoQueryCorrection[];
  groupByResults?: GroupByResult[];
  results?: SearchResult[];
}

export interface BasicQueryParameters {
  collection?: DocumentType;
  q?: string;
  aq?: string;
  f?: string;
  i?: number;
  n?: number;
  sortCriteria?: string;
  sortField?: string;
}

export interface CoveoQueryCorrection {
  correctedQuery?: string;
  wordCorrections?: CoveoWordCorrection[];
}

export interface CoveoWordCorrection {
  offset?: number;
  length?: number;
  originalWord?: string;
  correctedWord?: string;
}

export interface GroupByResult {
  field?: string;
  values?: GroupByResultValue[];
}

export interface GroupByResultValue {
  label?: string;
  value?: string;
  numberOfResults?: number;
}

export interface SearchResult {
  id?: string;
  title?: string;
  documentType?: string;
  tribunal?: string;
  juridiction?: string;
  date?: string;
  language?: string;
  excerpt?: string;
  firstSentences?: string;
  summary?: string;
  score?: number;
  percentScore?: number;
  /** Anything */
  parentResult?: any;
  /** Anything */
  childResults?: any;
  totalNumberOfChildResults?: number;
  /** Dictionary of <string> */
  raw?: { [propertyName: string]: string | null };
  url?: string;
}

export interface QueryParameters {
  facets?: Facet[];
  collection?: DocumentType;
  q?: string;
  aq?: string;
  f?: string;
  i?: number;
  n?: number;
  sortCriteria?: string;
  sortField?: string;
}

export interface Facet {
  field?: string;
  maxValues?: number;
  sort?: string;
  rangeValues?: RangeValue[];
  allowedValues?: string[];
  injectionDepth?: number;
  selectedValues?: string[];
  excludedValues?: string[];
}

export interface RangeValue {
  start?: string;
  end?: string;
  endInclusive?: boolean;
  label?: string;
}

export interface FacetSearchValuesResult {
  term?: string;
  values?: FacetSearchValue[];
}

export interface FacetSearchValue {
  value?: string;
  lookupValue?: string;
  numberOfResults?: number;
}

export interface FacetSearchQueryParameters {
  search?: QueryParameters;
  collection?: DocumentType;
  field?: string;
  sort?: string;
  maxResults?: number;
  term?: string;
}

export interface UserContentItemsResourcePagedList {
  list?: UserContentItemsResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface UserAlertItemsPagedList {
  list?: UserAlertItems[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface CoveoSourceDto {
  id?: number;
  coveoIdentifier?: string;
  name?: string;
  collection?: string;
  lastRefresh?: Date;
  juridiction?: string;
  coveoTask?: CoveoTaskDto;
}

export interface CoveoTaskDto {
  id?: number;
  coveoSourceId?: number;
  created?: Date;
  started?: Date;
  status?: string;
  task?: string;
  processed?: number;
  total?: number;
  checkpoint?: string;
  modifiedBy?: string;
}

export interface CoveoTaskHistoryDtoPagedList {
  list?: CoveoTaskHistoryDto[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface CoveoTaskHistoryDto {
  id?: number;
  coveoSourceId?: number;
  started?: Date;
  ended?: Date;
  status?: string;
  task?: string;
  processed?: number;
  total?: number;
  modifiedBy?: string;
  coveoSource?: CoveoSourceDto;
}

export interface CoveoLogsPagedList {
  list?: CoveoLogs[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface CoveoLogs {
  id?: number;
  coveoSourceId?: number;
  created?: Date;
  message?: string;
  coveoSource?: CoveoSource;
}

export interface CoveoSource {
  id?: number;
  coveoIdentifier?: string;
  name?: string;
  collection?: string;
  lastRefresh?: Date;
  juridiction?: string;
  coveoLogs?: CoveoLogs[];
  coveoTask?: CoveoTask;
  coveoTaskHistory?: CoveoTaskHistory[];
}

export interface CoveoTask {
  id?: number;
  coveoSourceId?: number;
  created?: Date;
  started?: Date;
  status?: string;
  task?: string;
  processed?: number;
  total?: number;
  checkpoint?: string;
  modifiedBy?: string;
  coveoSource?: CoveoSource;
}

export interface CoveoTaskHistory {
  id?: number;
  coveoSourceId?: number;
  started?: Date;
  ended?: Date;
  status?: string;
  task?: string;
  processed?: number;
  total?: number;
  modifiedBy?: string;
  coveoSource?: CoveoSource;
}

export interface LibraryServiceResource {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  requireParameters?: boolean;
  detailsFr?: string;
  detailsEn?: string;
  libraryServiceLibraries?: LibraryServiceLibraryDto[];
}

export interface LibraryServiceResourceForCreate {
  id?: number;
  nameFr: string;
  nameEn: string;
  requireParameters?: boolean;
  detailsFr?: string;
  detailsEn?: string;
  libraryServiceLibraries?: LibraryServiceLibraryDto[];
}

export interface LibraryServiceResourceForEdit {
  id?: number;
  nameFr: string;
  nameEn: string;
  requireParameters?: boolean;
  detailsFr?: string;
  detailsEn?: string;
  libraryServiceLibraries?: LibraryServiceLibraryDto[];
}

export interface SubscriptionResource {
  id?: number;
  name?: string;
  startDate?: Date;
  endDate?: Date;
  active?: boolean;
  sendLcapNotif?: boolean;
  doorCardProfile?: number;
  wfProfile?: string;
  uxpertiseGroup?: number;
  group?: SubscriptionGroupType;
  ownershipData?: DataOwnership;
  hasContent?: boolean;
  restrictedAccountCreation?: boolean;
  note?: string;
  logoUrl?: string;
  contact?: ContactResource;
  subscriptionGroup?: SubscriptionGroupResource;
  databanks?: DatabankItemResource[];
  documentCollections?: DocumentCollectionItemResource[];
  products?: ProductItemResource[];
  groups?: GroupDto[];
}

export interface ContactResource {
  name?: string;
  email?: string;
  phone?: string;
  address?: AddressResource;
}

export interface AddressResource {
  id?: number;
  line1?: string;
  line2?: string;
  city?: string;
  zip?: string;
  state?: string;
  country?: string;
}

export interface SubscriptionGroupResource {
  id?: number;
  name?: string;
  isDefault?: boolean;
  note?: string;
  groupType?: number;
  wfProfile?: string;
  accountRenewMode?: AccountRenewalMode;
  accountExpiryMode?: AccountExpiryType;
  accountExpiry?: AccountRenewalExpiryOptions;
  emailTemplateId?: number;
  formUrl?: string;
  databanks?: DatabankItemResource[];
  documentCollections?: DocumentCollectionItemResource[];
  products?: ProductItemResource[];
}

export interface AccountRenewalExpiryOptions {
  expireInDays?: number;
  expireOn?: AccountRenewalExpiryOnOptions;
}

export interface AccountRenewalExpiryOnOptions {
  day?: number;
  month?: number;
}

export interface DocumentCollectionItemResource {
  id?: number;
  code?: string;
  nameFr?: string;
  nameEn?: string;
  access?: AccessType;
  active?: boolean;
}

export interface ProductItemResource {
  id?: number;
  nameFr?: string;
  nameEn?: string;
  code?: string;
  access?: AccessType;
  active?: boolean;
  parametersRequired?: boolean;
  parameter?: number;
}

export interface SubscriptionResourceForCreate {
  id?: number;
  name: string;
  startDate: Date;
  endDate?: Date;
  active?: boolean;
  sendLcapNotif?: boolean;
  doorCardProfile?: number;
  wfProfile?: string;
  uxpertiseGroup?: number;
  group: SubscriptionGroupType;
  ownershipData: DataOwnership;
  hasContent?: boolean;
  restrictedAccountCreation?: boolean;
  note?: string;
  logoUrl?: string;
  contact?: ContactResource;
  subscriptionGroup?: SubscriptionGroupResource;
  databanks?: number[];
  documentCollections?: number[];
  products?: SubscriptionProductResource[];
}

export interface SubscriptionResourceForEdit {
  id?: number;
  name: string;
  startDate: Date;
  endDate?: Date;
  active?: boolean;
  sendLcapNotif?: boolean;
  doorCardProfile?: number;
  wfProfile?: string;
  uxpertiseGroup?: number;
  group: SubscriptionGroupType;
  ownershipData: DataOwnership;
  hasContent?: boolean;
  restrictedAccountCreation?: boolean;
  note?: string;
  logoUrl?: string;
  contact?: ContactResource;
  subscriptionGroup?: SubscriptionGroupResource;
  databanks?: number[];
  documentCollections?: number[];
  products?: SubscriptionProductResource[];
}

export interface SubscriptionGroupResourceForEdit {
  name: string;
  note?: string;
  groupType: number;
  wfProfile?: string;
  formUrl?: string;
  accountRenewMode?: AccountRenewalMode;
  accountExpiryMode?: AccountExpiryType;
  accountExpiry?: AccountRenewalExpiryOptions;
  emailTemplateId?: number;
  databanks?: number[];
  documentCollections?: number[];
  products?: SubscriptionProductResource[];
}

export interface WfLibraryDto {
  id?: number;
  name?: string;
  /** NOTE: This property will not be serialized. It can only be populated by the server. */
  readonly activated?: boolean;
  deleted?: boolean;
}

export interface WfProfileDto {
  id?: number;
  name?: string;
  /** NOTE: This property will not be serialized. It can only be populated by the server. */
  readonly activated?: boolean;
  deleted?: boolean;
}

export interface DocumentOptionLawDomainResourcePagedList {
  list?: DocumentOptionLawDomainResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface DocumentOptionLawDomainResource {
  lawDomainField?: LawDomainFieldDto[];
  rssDate?: Date;
  identifier?: string;
  documentId?: string;
  docUrl?: string;
  title?: string;
  collection?: string;
  cover?: string;
  metadatas?: DocumentMetaField;
  published?: Date;
  modified?: Date;
}

export interface TopoLawDomainNavigationResource {
  id?: number;
  nameFr?: string;
  nameEn?: string;
}

export interface TransactionDto {
  items?: TransactionItem[];
  customerInfo?: TransactionCustomerInfo;
  amount?: number;
  tps?: number;
  tvq?: number;
  totalAmount?: number;
  token?: string;
  clientToken?: string;
  userId?: string;
}

export interface TransactionItem {
  type?: number;
  id?: string;
  label?: string;
  description?: string;
  amount?: number;
  tps?: number;
  tvq?: number;
  totalAmount?: number;
}

export interface TransactionCustomerInfo {
  adresse1?: string;
  adresse2?: string;
  codePostal?: string;
  courriel?: string;
  firstName?: string;
  lastName?: string;
  noMembre?: string;
  province?: string;
  room?: string;
  rue?: string;
  ville?: string;
}

export interface TransactionResponse {
  transactionId?: string;
  customInfo?: TransactionCustomInfo;
  paymentMode?: string;
  paymentTimestamp?: Date;
  success?: boolean;
  message?: string;
}

export interface TransactionCustomInfo {
  invoiceId?: string;
  fileId?: string;
  requestId?: string;
}

export interface InvoiceInfoPagedList {
  list?: InvoiceInfo[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface TranslationResource {
  id?: number;
  code?: string;
  fr?: string;
  en?: string;
}

export interface TranslationResourceForCreate {
  id?: number;
  code: string;
  fr: string;
  en: string;
}

export interface TranslationResourcePagedList {
  list?: TranslationResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface TranslationResourceForEdit {
  id?: number;
  code: string;
  fr: string;
  en: string;
}

export interface TribunalResourcePagedList {
  list?: TribunalResource[];
  currentPage?: number;
  totalPages?: number;
  pageSize?: number;
  totalCount?: number;
}

export interface TribunalResource {
  id?: number;
  codeFr?: string;
  codeEn?: string;
  labelFr?: string;
  labelEn?: string;
  juridiction?: string;
  tribunalType?: number;
  tribunalTypeObj?: TribunalTypeResource;
  coverage?: string;
  otherCodes?: string[];
}

export interface TribunalTypeResource {
  id?: number;
  labelFr?: string;
  labelEn?: string;
}

export interface TribunalResourceForCreate {
  codeFr: string;
  codeEn: string;
  labelFr: string;
  labelEn: string;
  juridiction: string;
  tribunalType: number;
  coverage?: string;
  otherCodes?: string[];
}

export interface TribunalResourceForEdit {
  id?: number;
  codeFr: string;
  codeEn: string;
  labelFr: string;
  labelEn: string;
  juridiction: string;
  tribunalType: number;
  coverage?: string;
  tribunalTypeNavigation?: TribunalTypeResource;
  otherCodes?: string[];
}

export interface TribunalCodesResource {
  juridiction?: string;
  tribunal?: string;
}

export interface TribunalTypeResourceForCreate {
  id?: number;
  labelFr: string;
  labelEn: string;
}

export interface TribunalTypeResourceForEdit {
  id?: number;
  labelFr: string;
  labelEn: string;
}

export interface LibraryTypeResourceForCreate {
  id?: number;
  nameFr?: string;
  nameEn?: string;
}

export interface LibraryTypeResourceForEdit {
  id?: number;
  nameFr?: string;
  nameEn?: string;
}

export interface DocumentTypeResource {
  nameFr?: string;
  nameEn?: string;
  code?: string;
  description?: string;
}

export interface DocumentTypeResourceForCreate {
  nameFr: string;
  nameEn: string;
  code: string;
  description?: string;
}

export interface DocumentTypeResourceForEdit {
  nameFr: string;
  nameEn: string;
  code: string;
  description?: string;
}

export interface Version {
  build?: string;
  commit?: string;
}

export interface PathsOlajdmReperageIdFilePostRequestbodyContentMultipartFormDataSchema {
  file?: coreRestPipeline.RequestBodyType;
}

/** Known values of {@link AccountStatus} that the service accepts. */
export enum KnownAccountStatus {
  /** Inactive */
  Inactive = "Inactive",
  /** Active */
  Active = "Active",
  /** ActiveUponApproval */
  ActiveUponApproval = "ActiveUponApproval",
  /** ActiveOnLogin */
  ActiveOnLogin = "ActiveOnLogin"
}

/**
 * Defines values for AccountStatus. \
 * {@link KnownAccountStatus} can be used interchangeably with AccountStatus,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Inactive** \
 * **Active** \
 * **ActiveUponApproval** \
 * **ActiveOnLogin**
 */
export type AccountStatus = string;

/** Known values of {@link AccessType} that the service accepts. */
export enum KnownAccessType {
  /** Public */
  Public = "PUBLIC",
  /** Authentication */
  Authentication = "AUTHENTICATION",
  /** Private */
  Private = "PRIVATE"
}

/**
 * Defines values for AccessType. \
 * {@link KnownAccessType} can be used interchangeably with AccessType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **PUBLIC** \
 * **AUTHENTICATION** \
 * **PRIVATE**
 */
export type AccessType = string;

/** Known values of {@link DocumentType} that the service accepts. */
export enum KnownDocumentType {
  /** AnnotatedLaw */
  AnnotatedLaw = "AnnotatedLaw",
  /** Doctrine */
  Doctrine = "Doctrine",
  /** Law */
  Law = "Law",
  /** CaseLaws */
  CaseLaws = "CaseLaws",
  /** Library */
  Library = "Library",
  /** Topo */
  Topo = "Topo",
  /** Dictionary */
  Dictionary = "Dictionary",
  /** SpecialFiles */
  SpecialFiles = "SpecialFiles",
  /** Cies */
  Cies = "Cies",
  /** SpecialCollaboration */
  SpecialCollaboration = "SpecialCollaboration",
  /** Templates */
  Templates = "Templates"
}

/**
 * Defines values for DocumentType. \
 * {@link KnownDocumentType} can be used interchangeably with DocumentType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **AnnotatedLaw** \
 * **Doctrine** \
 * **Law** \
 * **CaseLaws** \
 * **Library** \
 * **Topo** \
 * **Dictionary** \
 * **SpecialFiles** \
 * **Cies** \
 * **SpecialCollaboration** \
 * **Templates**
 */
export type DocumentType = string;

/** Known values of {@link CracFeeType} that the service accepts. */
export enum KnownCracFeeType {
  /** Fixed */
  Fixed = "Fixed",
  /** Percent */
  Percent = "Percent"
}

/**
 * Defines values for CracFeeType. \
 * {@link KnownCracFeeType} can be used interchangeably with CracFeeType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Fixed** \
 * **Percent**
 */
export type CracFeeType = string;

/** Known values of {@link JurisdictionType} that the service accepts. */
export enum KnownJurisdictionType {
  /** None */
  None = "None",
  /** Federal */
  Federal = "Federal",
  /** Quebec */
  Quebec = "Quebec",
  /** Ontario */
  Ontario = "Ontario",
  /** BritishColumbia */
  BritishColumbia = "BritishColumbia",
  /** Alberta */
  Alberta = "Alberta",
  /** Saskatchewan */
  Saskatchewan = "Saskatchewan",
  /** Manitoba */
  Manitoba = "Manitoba",
  /** NovaScotia */
  NovaScotia = "NovaScotia",
  /** NewBrunswick */
  NewBrunswick = "NewBrunswick",
  /** PrinceEdwardIsland */
  PrinceEdwardIsland = "PrinceEdwardIsland",
  /** NewfoundlandLabrador */
  NewfoundlandLabrador = "NewfoundlandLabrador",
  /** NorthwestTerritories */
  NorthwestTerritories = "NorthwestTerritories",
  /** Yukon */
  Yukon = "Yukon",
  /** Nunavut */
  Nunavut = "Nunavut"
}

/**
 * Defines values for JurisdictionType. \
 * {@link KnownJurisdictionType} can be used interchangeably with JurisdictionType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **None** \
 * **Federal** \
 * **Quebec** \
 * **Ontario** \
 * **BritishColumbia** \
 * **Alberta** \
 * **Saskatchewan** \
 * **Manitoba** \
 * **NovaScotia** \
 * **NewBrunswick** \
 * **PrinceEdwardIsland** \
 * **NewfoundlandLabrador** \
 * **NorthwestTerritories** \
 * **Yukon** \
 * **Nunavut**
 */
export type JurisdictionType = string;

/** Known values of {@link ProductType} that the service accepts. */
export enum KnownProductType {
  /** Search */
  Search = "SEARCH",
  /** Cover */
  Cover = "COVER"
}

/**
 * Defines values for ProductType. \
 * {@link KnownProductType} can be used interchangeably with ProductType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **SEARCH** \
 * **COVER**
 */
export type ProductType = string;

/** Known values of {@link SearchInputType} that the service accepts. */
export enum KnownSearchInputType {
  /** Name */
  Name = "Name",
  /** No */
  No = "No"
}

/**
 * Defines values for SearchInputType. \
 * {@link KnownSearchInputType} can be used interchangeably with SearchInputType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Name** \
 * **No**
 */
export type SearchInputType = string;

/** Known values of {@link SearchJurisdictionType} that the service accepts. */
export enum KnownSearchJurisdictionType {
  /** ALL */
  ALL = "ALL",
  /** QuebecFED */
  QuebecFED = "QUEBEC_FED"
}

/**
 * Defines values for SearchJurisdictionType. \
 * {@link KnownSearchJurisdictionType} can be used interchangeably with SearchJurisdictionType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **ALL** \
 * **QUEBEC_FED**
 */
export type SearchJurisdictionType = string;

/** Known values of {@link PaymentType} that the service accepts. */
export enum KnownPaymentType {
  /** None */
  None = "NONE",
  /** Free */
  Free = "FREE",
  /** CaijCard */
  CaijCard = "CAIJ_CARD"
}

/**
 * Defines values for PaymentType. \
 * {@link KnownPaymentType} can be used interchangeably with PaymentType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **NONE** \
 * **FREE** \
 * **CAIJ_CARD**
 */
export type PaymentType = string;

/** Known values of {@link CustomerRole} that the service accepts. */
export enum KnownCustomerRole {
  /** User */
  User = "User",
  /** Admin */
  Admin = "Admin",
  /** Owner */
  Owner = "Owner"
}

/**
 * Defines values for CustomerRole. \
 * {@link KnownCustomerRole} can be used interchangeably with CustomerRole,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **User** \
 * **Admin** \
 * **Owner**
 */
export type CustomerRole = string;

/** Known values of {@link Lang} that the service accepts. */
export enum KnownLang {
  /** FR */
  FR = "FR",
  /** EN */
  EN = "EN"
}

/**
 * Defines values for Lang. \
 * {@link KnownLang} can be used interchangeably with Lang,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **FR** \
 * **EN**
 */
export type Lang = string;

/** Known values of {@link SubscriptionGroupType} that the service accepts. */
export enum KnownSubscriptionGroupType {
  /** Both */
  Both = "Both",
  /** B2B */
  B2B = "B2B",
  /** B2C */
  B2C = "B2C"
}

/**
 * Defines values for SubscriptionGroupType. \
 * {@link KnownSubscriptionGroupType} can be used interchangeably with SubscriptionGroupType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Both** \
 * **B2B** \
 * **B2C**
 */
export type SubscriptionGroupType = string;

/** Known values of {@link DataOwnership} that the service accepts. */
export enum KnownDataOwnership {
  /** Individual */
  Individual = "INDIVIDUAL",
  /** Enterprise */
  Enterprise = "ENTERPRISE"
}

/**
 * Defines values for DataOwnership. \
 * {@link KnownDataOwnership} can be used interchangeably with DataOwnership,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **INDIVIDUAL** \
 * **ENTERPRISE**
 */
export type DataOwnership = string;

/** Known values of {@link DatabankAppType} that the service accepts. */
export enum KnownDatabankAppType {
  /** Unknown */
  Unknown = "Unknown",
  /** Desktop */
  Desktop = "Desktop",
  /** Online */
  Online = "Online"
}

/**
 * Defines values for DatabankAppType. \
 * {@link KnownDatabankAppType} can be used interchangeably with DatabankAppType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Unknown** \
 * **Desktop** \
 * **Online**
 */
export type DatabankAppType = string;

/** Known values of {@link DatabankStatusOption} that the service accepts. */
export enum KnownDatabankStatusOption {
  /** Inactive */
  Inactive = "Inactive",
  /** Active */
  Active = "Active"
}

/**
 * Defines values for DatabankStatusOption. \
 * {@link KnownDatabankStatusOption} can be used interchangeably with DatabankStatusOption,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Inactive** \
 * **Active**
 */
export type DatabankStatusOption = string;

/** Known values of {@link DatabankAccessType} that the service accepts. */
export enum KnownDatabankAccessType {
  /** Library */
  Library = "Library",
  /** Remote */
  Remote = "Remote"
}

/**
 * Defines values for DatabankAccessType. \
 * {@link KnownDatabankAccessType} can be used interchangeably with DatabankAccessType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Library** \
 * **Remote**
 */
export type DatabankAccessType = string;

/** Known values of {@link DatabankLinkType} that the service accepts. */
export enum KnownDatabankLinkType {
  /** None */
  None = "None",
  /** Caij */
  Caij = "Caij",
  /** Bibliomagis */
  Bibliomagis = "Bibliomagis",
  /** Direct */
  Direct = "Direct",
  /** Clavardage */
  Clavardage = "Clavardage"
}

/**
 * Defines values for DatabankLinkType. \
 * {@link KnownDatabankLinkType} can be used interchangeably with DatabankLinkType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **None** \
 * **Caij** \
 * **Bibliomagis** \
 * **Direct** \
 * **Clavardage**
 */
export type DatabankLinkType = string;

/** Known values of {@link AccountRenewalMode} that the service accepts. */
export enum KnownAccountRenewalMode {
  /** NotRenewable */
  NotRenewable = "NotRenewable",
  /** OnApproval */
  OnApproval = "OnApproval",
  /** SelfRenewal */
  SelfRenewal = "SelfRenewal",
  /** EmailConfirmRenew */
  EmailConfirmRenew = "EmailConfirmRenew"
}

/**
 * Defines values for AccountRenewalMode. \
 * {@link KnownAccountRenewalMode} can be used interchangeably with AccountRenewalMode,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **NotRenewable** \
 * **OnApproval** \
 * **SelfRenewal** \
 * **EmailConfirmRenew**
 */
export type AccountRenewalMode = string;

/** Known values of {@link AccountExpiryType} that the service accepts. */
export enum KnownAccountExpiryType {
  /** NeverExpire */
  NeverExpire = "NeverExpire",
  /** ExpireOnDate */
  ExpireOnDate = "ExpireOnDate",
  /** ExpireAfterDays */
  ExpireAfterDays = "ExpireAfterDays",
  /** DateSpecifiedAtCreation */
  DateSpecifiedAtCreation = "DateSpecifiedAtCreation"
}

/**
 * Defines values for AccountExpiryType. \
 * {@link KnownAccountExpiryType} can be used interchangeably with AccountExpiryType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **NeverExpire** \
 * **ExpireOnDate** \
 * **ExpireAfterDays** \
 * **DateSpecifiedAtCreation**
 */
export type AccountExpiryType = string;

/** Known values of {@link PendingChangeType} that the service accepts. */
export enum KnownPendingChangeType {
  /** Email */
  Email = "Email",
  /** Activation */
  Activation = "Activation",
  /** Renewable */
  Renewable = "Renewable",
  /** CardReplace */
  CardReplace = "CardReplace",
  /** CardRequest */
  CardRequest = "CardRequest"
}

/**
 * Defines values for PendingChangeType. \
 * {@link KnownPendingChangeType} can be used interchangeably with PendingChangeType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Email** \
 * **Activation** \
 * **Renewable** \
 * **CardReplace** \
 * **CardRequest**
 */
export type PendingChangeType = string;

/** Known values of {@link CardTransactionType} that the service accepts. */
export enum KnownCardTransactionType {
  /** Refill */
  Refill = "Refill",
  /** Payment */
  Payment = "Payment",
  /** Refund */
  Refund = "Refund",
  /** Withdrawal */
  Withdrawal = "Withdrawal"
}

/**
 * Defines values for CardTransactionType. \
 * {@link KnownCardTransactionType} can be used interchangeably with CardTransactionType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Refill** \
 * **Payment** \
 * **Refund** \
 * **Withdrawal**
 */
export type CardTransactionType = string;

/** Known values of {@link ExternalAppType} that the service accepts. */
export enum KnownExternalAppType {
  /** Symphony */
  Symphony = "Symphony",
  /** Uxpertise */
  Uxpertise = "Uxpertise",
  /** Ldap */
  Ldap = "LDAP",
  /** LRC */
  LRC = "LRC"
}

/**
 * Defines values for ExternalAppType. \
 * {@link KnownExternalAppType} can be used interchangeably with ExternalAppType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Symphony** \
 * **Uxpertise** \
 * **LDAP** \
 * **LRC**
 */
export type ExternalAppType = string;

/** Known values of {@link UserContentItemType} that the service accepts. */
export enum KnownUserContentItemType {
  /** Document */
  Document = "Document",
  /** Query */
  Query = "Query",
  /** File */
  File = "File"
}

/**
 * Defines values for UserContentItemType. \
 * {@link KnownUserContentItemType} can be used interchangeably with UserContentItemType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Document** \
 * **Query** \
 * **File**
 */
export type UserContentItemType = string;

/** Known values of {@link PersonType} that the service accepts. */
export enum KnownPersonType {
  /** Unknown */
  Unknown = "UNKNOWN",
  /** Judge */
  Judge = "JUDGE",
  /** Attorney */
  Attorney = "ATTORNEY"
}

/**
 * Defines values for PersonType. \
 * {@link KnownPersonType} can be used interchangeably with PersonType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **UNKNOWN** \
 * **JUDGE** \
 * **ATTORNEY**
 */
export type PersonType = string;

/** Known values of {@link EmployeeRole} that the service accepts. */
export enum KnownEmployeeRole {
  /** User */
  User = "USER",
  /** Admin */
  Admin = "ADMIN"
}

/**
 * Defines values for EmployeeRole. \
 * {@link KnownEmployeeRole} can be used interchangeably with EmployeeRole,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **USER** \
 * **ADMIN**
 */
export type EmployeeRole = string;

/** Known values of {@link MessagePlaceholderType} that the service accepts. */
export enum KnownMessagePlaceholderType {
  /** Accueil */
  Accueil = "ACCUEIL",
  /** Unik */
  Unik = "UNIK",
  /** Global */
  Global = "GLOBAL",
  /** Jurisprudence */
  Jurisprudence = "JURISPRUDENCE",
  /** Doctrine */
  Doctrine = "DOCTRINE",
  /** LGislation */
  LGislation = "LÉGISLATION",
  /** Formulaires */
  Formulaires = "FORMULAIRES",
  /** Entreprise */
  Entreprise = "Entreprise",
  /** BD */
  BD = "BD",
  /** TopoBC */
  TopoBC = "TOPO_BC",
  /** DicoJuri */
  DicoJuri = "DICO_JURI",
  /** Membre */
  Membre = "MEMBRE"
}

/**
 * Defines values for MessagePlaceholderType. \
 * {@link KnownMessagePlaceholderType} can be used interchangeably with MessagePlaceholderType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **ACCUEIL** \
 * **UNIK** \
 * **GLOBAL** \
 * **JURISPRUDENCE** \
 * **DOCTRINE** \
 * **LÉGISLATION** \
 * **FORMULAIRES** \
 * **Entreprise** \
 * **BD** \
 * **TOPO_BC** \
 * **DICO_JURI** \
 * **MEMBRE**
 */
export type MessagePlaceholderType = string;

/** Known values of {@link CartItemType} that the service accepts. */
export enum KnownCartItemType {
  /** Delivery */
  Delivery = "Delivery",
  /** Reservation */
  Reservation = "Reservation",
  /** Order */
  Order = "Order"
}

/**
 * Defines values for CartItemType. \
 * {@link KnownCartItemType} can be used interchangeably with CartItemType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Delivery** \
 * **Reservation** \
 * **Order**
 */
export type CartItemType = string;

/** Optional parameters. */
export interface GetUserSearchTokenAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getUserSearchTokenAsync operation. */
export type GetUserSearchTokenAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface GetHasNewNoticeAsyncOptionalParams
  extends coreClient.OperationOptions {
  dateRss?: string;
}

/** Contains response data for the getHasNewNoticeAsync operation. */
export type GetHasNewNoticeAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface GetApiKeysListOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getApiKeysList operation. */
export type GetApiKeysListResponse = ApiKeyDto[];

/** Optional parameters. */
export interface CreateApiKeyAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: ApiKeySettings;
}

/** Contains response data for the createApiKeyAsync operation. */
export type CreateApiKeyAsyncResponse = ApiKeyDto;

/** Optional parameters. */
export interface UpdateApiKeyAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: ApiKeySettings;
}

/** Contains response data for the updateApiKeyAsync operation. */
export type UpdateApiKeyAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteApiKeyAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteApiKeyAsync operation. */
export type DeleteApiKeyAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetAccountsByEmailAsyncOptionalParams
  extends coreClient.OperationOptions {
  identifier?: string;
}

/** Contains response data for the getAccountsByEmailAsync operation. */
export type GetAccountsByEmailAsyncResponse = LoginAccountResource[];

/** Optional parameters. */
export interface LoginAsyncOptionalParams extends coreClient.OperationOptions {
  body?: LoginResource;
}

/** Contains response data for the loginAsync operation. */
export type LoginAsyncResponse = CustomerLoginResponse;

/** Optional parameters. */
export interface LogoutAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Optional parameters. */
export interface SendForgotPasswordEmailAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: PasswordResetRequestResource;
}

/** Contains response data for the sendForgotPasswordEmailAsync operation. */
export type SendForgotPasswordEmailAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface ValidateForgotLinkAsyncOptionalParams
  extends coreClient.OperationOptions {
  token?: string;
  code?: string;
}

/** Contains response data for the validateForgotLinkAsync operation. */
export type ValidateForgotLinkAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface ResetPasswordAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: PasswordResetResource;
}

/** Contains response data for the resetPasswordAsync operation. */
export type ResetPasswordAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetScottAZIndexOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getScottAZIndex operation. */
export type GetScottAZIndexResponse = { [propertyName: string]: number };

/** Optional parameters. */
export interface GetScottNavByIdOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getScottNavById operation. */
export type GetScottNavByIdResponse = DocumentInfoResource[];

/** Optional parameters. */
export interface GetPeriodicalCallNumbersOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getPeriodicalCallNumbers operation. */
export type GetPeriodicalCallNumbersResponse = PeriodicalResource;

/** Optional parameters. */
export interface GetCallNumberArticlesOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getCallNumberArticles operation. */
export type GetCallNumberArticlesResponse = DocumentInfoResource[];

/** Optional parameters. */
export interface GetBiblioLatestOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  contentType?: string;
}

/** Contains response data for the getBiblioLatest operation. */
export type GetBiblioLatestResponse = DocumentInfoResource[];

/** Optional parameters. */
export interface GetBiblioCatalogAvailabilitiesOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  showSerialHoldings?: boolean;
  coteToFilter?: string;
}

/** Contains response data for the getBiblioCatalogAvailabilities operation. */
export type GetBiblioCatalogAvailabilitiesResponse = BiblioDispoResult;

/** Optional parameters. */
export interface GetcaselawsOptionalParams extends coreClient.OperationOptions {
  lang?: string;
  jurisdiction?: string;
  tribunalId?: number;
  year?: string;
  month?: string;
  totalResults?: number;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
}

/** Contains response data for the getcaselaws operation. */
export type GetcaselawsResponse = CaseLawsResourcePagedList;

/** Optional parameters. */
export interface GetcaselawsfilteryearsOptionalParams
  extends coreClient.OperationOptions {
  tribunalCode?: string;
}

/** Contains response data for the getcaselawsfilteryears operation. */
export type GetcaselawsfilteryearsResponse = {
  /** The parsed response body. */
  body: number[];
};

/** Optional parameters. */
export interface GetcaselawsfiltermonthsOptionalParams
  extends coreClient.OperationOptions {
  lang?: string;
  jurisdiction?: string;
  tribunalId?: number;
  year?: string;
  searchTerm?: string;
}

/** Contains response data for the getcaselawsfiltermonths operation. */
export type GetcaselawsfiltermonthsResponse = {
  /** The parsed response body. */
  body: number[];
};

/** Optional parameters. */
export interface GetCatalogAvailabilitiesOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  showSerialHoldings?: boolean;
  coteToFilter?: string;
}

/** Contains response data for the getCatalogAvailabilities operation. */
export type GetCatalogAvailabilitiesResponse = BiblioDispoResult;

/** Optional parameters. */
export interface GetCiesTocDocumentsOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getCiesTocDocuments operation. */
export type GetCiesTocDocumentsResponse = PublicDocumentOptionResource[];

/** Optional parameters. */
export interface GetCollectionsAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getCollectionsAsync operation. */
export type GetCollectionsAsyncResponse = DocumentCollectionResource[];

/** Optional parameters. */
export interface CreateCollectionAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: DocumentCollectionResourceForCreate;
}

/** Contains response data for the createCollectionAsync operation. */
export type CreateCollectionAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetCollectionAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getCollectionAsync operation. */
export type GetCollectionAsyncResponse = DocumentCollectionResource;

/** Optional parameters. */
export interface UpdateCollectionAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: DocumentCollectionResourceForEdit;
}

/** Contains response data for the updateCollectionAsync operation. */
export type UpdateCollectionAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteCollectionAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteCollectionAsync operation. */
export type DeleteCollectionAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface ClearSearchTokenAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the clearSearchTokenAsync operation. */
export type ClearSearchTokenAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface GetCollectionDocumentsAsyncOptionalParams
  extends coreClient.OperationOptions {
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
}

/** Contains response data for the getCollectionDocumentsAsync operation. */
export type GetCollectionDocumentsAsyncResponse = DocumentCollectionsResourcePagedList;

/** Optional parameters. */
export interface CreateCollectionDocumentAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: DocumentCollectionsResourceForCreate;
}

/** Contains response data for the createCollectionDocumentAsync operation. */
export type CreateCollectionDocumentAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetCollectionDocumentAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getCollectionDocumentAsync operation. */
export type GetCollectionDocumentAsyncResponse = DocumentCollectionsResource;

/** Optional parameters. */
export interface UpdateCollectionDocumentAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: DocumentCollectionsResourceForEdit;
}

/** Contains response data for the updateCollectionDocumentAsync operation. */
export type UpdateCollectionDocumentAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteCollectionDocumentAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteCollectionDocumentAsync operation. */
export type DeleteCollectionDocumentAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetDocumentTypeAccessesOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getDocumentTypeAccesses operation. */
export type GetDocumentTypeAccessesResponse = {
  /** The parsed response body. */
  body: DocumentType[];
};

/** Optional parameters. */
export interface GetFreeSearchesRemainingOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getFreeSearchesRemaining operation. */
export type GetFreeSearchesRemainingResponse = {
  /** The parsed response body. */
  body: number;
};

/** Optional parameters. */
export interface GetCracConfigurationsOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getCracConfigurations operation. */
export type GetCracConfigurationsResponse = ClientSettingsDto;

/** Optional parameters. */
export interface FetchMoreCracResultAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the fetchMoreCracResultAsync operation. */
export type FetchMoreCracResultAsyncResponse = SearchResultsDto;

/** Optional parameters. */
export interface MakeCracSearchOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: CracSearchResource;
}

/** Contains response data for the makeCracSearch operation. */
export type MakeCracSearchResponse = SearchResultsDto;

/** Optional parameters. */
export interface GetCracDocumentOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getCracDocument operation. */
export type GetCracDocumentResponse = {
  /** The parsed response body. */
  body: Uint8Array;
};

/** Optional parameters. */
export interface DeleteCracFileOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the deleteCracFile operation. */
export type DeleteCracFileResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface SendCracFileByEmailOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: SendCracFileByEmailDto;
}

/** Contains response data for the sendCracFileByEmail operation. */
export type SendCracFileByEmailResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface GetOrdersCountAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getOrdersCountAsync operation. */
export type GetOrdersCountAsyncResponse = {
  /** The parsed response body. */
  body: number;
};

/** Optional parameters. */
export interface GetDocumentOrderedAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  collection?: string;
  documentId?: string;
  title?: string;
  /** Array of Get3ItemsItem */
  lawDomainIds?: number[];
  filter?: string;
  visibleOnly?: boolean;
}

/** Contains response data for the getDocumentOrderedAsync operation. */
export type GetDocumentOrderedAsyncResponse = DocumentOrderedDtoPagedList;

/** Optional parameters. */
export interface OrderCracDocumentAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: DocumentOrderResource;
}

/** Contains response data for the orderCracDocumentAsync operation. */
export type OrderCracDocumentAsyncResponse = DocumentOrderResultDto;

/** Optional parameters. */
export interface GetCustomersAsyncOptionalParams
  extends coreClient.OperationOptions {
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  status?: string;
  delayAccountCustomer?: boolean;
  isEndorsedBy?: boolean;
  email?: string;
  noCaij?: string;
  wfProfile?: string;
  subscription?: string;
  group?: string;
  firstname?: string;
  lastname?: string;
  extIdentifier?: string;
  card?: string;
  subscriptionId?: number;
  role?: string;
  productAccess?: string;
}

/** Contains response data for the getCustomersAsync operation. */
export type GetCustomersAsyncResponse = CustomerResourcePagedList;

/** Optional parameters. */
export interface CreateCustomerAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: CustomerResourceForCreate;
}

/** Contains response data for the createCustomerAsync operation. */
export type CreateCustomerAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetCustomerAsyncOptionalParams
  extends coreClient.OperationOptions {
  since?: Date;
}

/** Contains response data for the getCustomerAsync operation. */
export type GetCustomerAsyncResponse = CustomerResource;

/** Optional parameters. */
export interface UpdateCustomerAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: CustomerResourceForEdit;
}

/** Contains response data for the updateCustomerAsync operation. */
export type UpdateCustomerAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteCustomerAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteCustomerAsync operation. */
export type DeleteCustomerAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface ModifyCustomerStatusAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: CustomerStatusDto;
}

/** Contains response data for the modifyCustomerStatusAsync operation. */
export type ModifyCustomerStatusAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface SendAccountActivationEmailAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the sendAccountActivationEmailAsync operation. */
export type SendAccountActivationEmailAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface SyncCustomerAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the syncCustomerAsync operation. */
export type SyncCustomerAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface ModifyCustomerEndorserAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: number;
}

/** Contains response data for the modifyCustomerEndorserAsync operation. */
export type ModifyCustomerEndorserAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetPictureAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getPictureAsync operation. */
export type GetPictureAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface DeletePictureAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deletePictureAsync operation. */
export type DeletePictureAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface GetCustomerHistoryListAsyncOptionalParams
  extends coreClient.OperationOptions {
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  customerId?: string;
  modifedBySearchTerm?: string;
  startDateSearchTerm?: Date;
  endDateSearchTerm?: Date;
}

/** Contains response data for the getCustomerHistoryListAsync operation. */
export type GetCustomerHistoryListAsyncResponse = CustomerHistoryResourcePagedList;

/** Optional parameters. */
export interface GetCustomerHistoryAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getCustomerHistoryAsync operation. */
export type GetCustomerHistoryAsyncResponse = CustomerHistoryResource[];

/** Optional parameters. */
export interface GetCustomerNoteHistoryAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getCustomerNoteHistoryAsync operation. */
export type GetCustomerNoteHistoryAsyncResponse = CustomerHistoryResource[];

/** Optional parameters. */
export interface CustomerPasswordResetOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the customerPasswordReset operation. */
export type CustomerPasswordResetResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface DeleteCustomerRequestOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteCustomerRequest operation. */
export type DeleteCustomerRequestResponse = ProblemDetails;

/** Optional parameters. */
export interface CustomerResendRequestOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the customerResendRequest operation. */
export type CustomerResendRequestResponse = ProblemDetails;

/** Optional parameters. */
export interface CustomerValidateRequestOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the customerValidateRequest operation. */
export type CustomerValidateRequestResponse = ProblemDetails;

/** Optional parameters. */
export interface SetCustomerLibraryAccountPinAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: string;
}

/** Contains response data for the setCustomerLibraryAccountPinAsync operation. */
export type SetCustomerLibraryAccountPinAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface GetCustomerLibraryAccountInfoAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getCustomerLibraryAccountInfoAsync operation. */
export type GetCustomerLibraryAccountInfoAsyncResponse = CustomerLibraryAccountInfoResource;

/** Optional parameters. */
export interface AssignCustomerCardAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: CardResource;
}

/** Contains response data for the assignCustomerCardAsync operation. */
export type AssignCustomerCardAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface UnassignCustomerCardAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: string;
}

/** Contains response data for the unassignCustomerCardAsync operation. */
export type UnassignCustomerCardAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetCustomerCardTransactionsAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getCustomerCardTransactionsAsync operation. */
export type GetCustomerCardTransactionsAsyncResponse = CustomerCardTransactionsResource;

/** Optional parameters. */
export interface MakeCardTransactionOptionalParams
  extends coreClient.OperationOptions {
  body?: LibraryCardTransaction;
}

/** Contains response data for the makeCardTransaction operation. */
export type MakeCardTransactionResponse = ProblemDetails;

/** Optional parameters. */
export interface GetCustomerInvoicesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getCustomerInvoicesAsync operation. */
export type GetCustomerInvoicesAsyncResponse = InvoiceInfo[];

/** Optional parameters. */
export interface GetCustomerInvoiceAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getCustomerInvoiceAsync operation. */
export type GetCustomerInvoiceAsyncResponse = InvoiceFileDto;

/** Optional parameters. */
export interface GetOrdersAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getOrdersAsync operation. */
export type GetOrdersAsyncResponse = DocumentOrderedDto[];

/** Optional parameters. */
export interface GetOrderAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getOrderAsync operation. */
export type GetOrderAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface GetCardRequestAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getCardRequestAsync operation. */
export type GetCardRequestAsyncResponse = CustomerPendingChangeResource[];

/** Optional parameters. */
export interface MakeCustomerCardRequestAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the makeCustomerCardRequestAsync operation. */
export type MakeCustomerCardRequestAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface CancelCustomerCardRequestAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the cancelCustomerCardRequestAsync operation. */
export type CancelCustomerCardRequestAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface CreateLearningAccountCustomerAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the createLearningAccountCustomerAsync operation. */
export type CreateLearningAccountCustomerAsyncResponse = {
  /** The parsed response body. */
  body: number;
};

/** Optional parameters. */
export interface GetmailStatusAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getmailStatusAsync operation. */
export type GetmailStatusAsyncResponse = EmailStatusDto[];

/** Optional parameters. */
export interface GetCustomerNotesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getCustomerNotesAsync operation. */
export type GetCustomerNotesAsyncResponse = CustomerNoteDto[];

/** Optional parameters. */
export interface CreateCustomerNoteAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: CustomerNoteForCreate;
}

/** Contains response data for the createCustomerNoteAsync operation. */
export type CreateCustomerNoteAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface UpdateCustomerNoteAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: CustomerNoteDto;
}

/** Contains response data for the updateCustomerNoteAsync operation. */
export type UpdateCustomerNoteAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteCustomerNoteAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteCustomerNoteAsync operation. */
export type DeleteCustomerNoteAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetActiveCustomerOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getActiveCustomer operation. */
export type GetActiveCustomerResponse = {
  /** The parsed response body. */
  body: number;
};

/** Optional parameters. */
export interface GetActiveCustomersBySubscriptionOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getActiveCustomersBySubscription operation. */
export type GetActiveCustomersBySubscriptionResponse = StatsDto[];

/** Optional parameters. */
export interface GetActiveCustomersByGroupOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getActiveCustomersByGroup operation. */
export type GetActiveCustomersByGroupResponse = StatsDto[];

/** Optional parameters. */
export interface GetCardRequestPendingCustomerOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getCardRequestPendingCustomer operation. */
export type GetCardRequestPendingCustomerResponse = {
  /** The parsed response body. */
  body: number;
};

/** Optional parameters. */
export interface GetDelayAccountCustomerOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getDelayAccountCustomer operation. */
export type GetDelayAccountCustomerResponse = {
  /** The parsed response body. */
  body: number;
};

/** Optional parameters. */
export interface CreateUserAccountAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: AccountCreateInfo;
}

/** Contains response data for the createUserAccountAsync operation. */
export type CreateUserAccountAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetCustomerAccountInfoAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getCustomerAccountInfoAsync operation. */
export type GetCustomerAccountInfoAsyncResponse = ModeleMembreWs;

/** Optional parameters. */
export interface UpdateCustomerAccountInfoAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: ModeleMembreWs;
}

/** Contains response data for the updateCustomerAccountInfoAsync operation. */
export type UpdateCustomerAccountInfoAsyncResponse = ModeleMembreWs;

/** Optional parameters. */
export interface UpdateCustomerBirthdayAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: CustomerBirthday;
}

/** Contains response data for the updateCustomerBirthdayAsync operation. */
export type UpdateCustomerBirthdayAsyncResponse = ModeleMembreWs;

/** Optional parameters. */
export interface GetCustomerAccountAccessAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getCustomerAccountAccessAsync operation. */
export type GetCustomerAccountAccessAsyncResponse = OldSubscriptionInfo;

/** Optional parameters. */
export interface RequestAccountCardAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: CustomerAddressDto;
}

/** Contains response data for the requestAccountCardAsync operation. */
export type RequestAccountCardAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface RenewUserAccountAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the renewUserAccountAsync operation. */
export type RenewUserAccountAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface PayCustomerFeesAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: Payment;
}

/** Contains response data for the payCustomerFeesAsync operation. */
export type PayCustomerFeesAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface SearchCustomerResearchServiceAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: SearchCustomerParams;
}

/** Contains response data for the searchCustomerResearchServiceAsync operation. */
export type SearchCustomerResearchServiceAsyncResponse = UserSearchData[];

/** Optional parameters. */
export interface GetLcapStatusForAccountOptionalParams
  extends coreClient.OperationOptions {
  email?: string;
}

/** Contains response data for the getLcapStatusForAccount operation. */
export type GetLcapStatusForAccountResponse = EmailSubscriptions;

/** Optional parameters. */
export interface SetLcapStatusForAccountOptionalParams
  extends coreClient.OperationOptions {
  body?: EmailSubscriptions;
}

/** Contains response data for the setLcapStatusForAccount operation. */
export type SetLcapStatusForAccountResponse = ProblemDetails;

/** Optional parameters. */
export interface GetFoldersAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  status?: string;
  id?: number;
}

/** Contains response data for the getFoldersAsync operation. */
export type GetFoldersAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface CreateFolderAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: FolderResource;
}

/** Contains response data for the createFolderAsync operation. */
export type CreateFolderAsyncResponse = CustomerContentDto;

/** Optional parameters. */
export interface UpdateFolderAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: FolderResource;
}

/** Contains response data for the updateFolderAsync operation. */
export type UpdateFolderAsyncResponse = CustomerContentDto;

/** Optional parameters. */
export interface DeleteFolderAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the deleteFolderAsync operation. */
export type DeleteFolderAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface ArchiveFolderAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the archiveFolderAsync operation. */
export type ArchiveFolderAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface UnarchiveFolderAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the unarchiveFolderAsync operation. */
export type UnarchiveFolderAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface GetUserFoldersAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  archived?: boolean;
  sortedDesc?: boolean;
  filterByName?: string;
}

/** Contains response data for the getUserFoldersAsync operation. */
export type GetUserFoldersAsyncResponse = UserFoldersResourcePagedList;

/** Optional parameters. */
export interface CreateUserFolderAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: UserFoldersResourceForCreate;
}

/** Contains response data for the createUserFolderAsync operation. */
export type CreateUserFolderAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetUserFolderByIdAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getUserFolderByIdAsync operation. */
export type GetUserFolderByIdAsyncResponse = UserFoldersResource;

/** Optional parameters. */
export interface UpdateUserFolderAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: UserFoldersResourceForEdit;
}

/** Contains response data for the updateUserFolderAsync operation. */
export type UpdateUserFolderAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteFolderByIdAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the deleteFolderByIdAsync operation. */
export type DeleteFolderByIdAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface ArchiveUserFolderAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: boolean;
}

/** Contains response data for the archiveUserFolderAsync operation. */
export type ArchiveUserFolderAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface CustomerFolderOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: UserContentItemsResourceForCreate;
}

/** Contains response data for the customerFolder operation. */
export type CustomerFolderResponse = ProblemDetails;

/** Optional parameters. */
export interface GetContentItemsAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  lang?: string;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  sortedDesc?: boolean;
  contentTypeId?: number;
}

/** Contains response data for the getContentItemsAsync operation. */
export type GetContentItemsAsyncResponse = UserContentItemsResource[];

/** Optional parameters. */
export interface SendFolderByEmailAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: SendFolderByEmailResource;
}

/** Contains response data for the sendFolderByEmailAsync operation. */
export type SendFolderByEmailAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface GetContentItemsByFolderAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  lang?: string;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  sortedDesc?: boolean;
  contentTypeId?: number;
}

/** Contains response data for the getContentItemsByFolderAsync operation. */
export type GetContentItemsByFolderAsyncResponse = UserContentItemsResource[];

/** Optional parameters. */
export interface GetContentItemByIdAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getContentItemByIdAsync operation. */
export type GetContentItemByIdAsyncResponse = UserContentItemsResource;

/** Optional parameters. */
export interface UpdateContentItemAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: UserContentItemsResourceForEdit;
}

/** Contains response data for the updateContentItemAsync operation. */
export type UpdateContentItemAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteContentItemsAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the deleteContentItemsAsync operation. */
export type DeleteContentItemsAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface SendItemByEmailAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: SendFolderByEmailResource;
}

/** Contains response data for the sendItemByEmailAsync operation. */
export type SendItemByEmailAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface GetItemDocumentFileAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getItemDocumentFileAsync operation. */
export type GetItemDocumentFileAsyncResponse = RequestedDocumentFile;

/** Optional parameters. */
export interface GetDatabanksAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getDatabanksAsync operation. */
export type GetDatabanksAsyncResponse = DatabankResource[];

/** Optional parameters. */
export interface CreateDatabankAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: DatabankResourceForCreate;
}

/** Contains response data for the createDatabankAsync operation. */
export type CreateDatabankAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetDatabankByIdAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getDatabankByIdAsync operation. */
export type GetDatabankByIdAsyncResponse = DatabankResource;

/** Optional parameters. */
export interface UpdateDatabankAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: DatabankResourceForEdit;
}

/** Contains response data for the updateDatabankAsync operation. */
export type UpdateDatabankAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteDatabankAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteDatabankAsync operation. */
export type DeleteDatabankAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetDatabankStatusAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getDatabankStatusAsync operation. */
export type GetDatabankStatusAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetDatabankProxyDomainsAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getDatabankProxyDomainsAsync operation. */
export type GetDatabankProxyDomainsAsyncResponse = DatabankWithProxyResource;

/** Optional parameters. */
export interface GetByLangOptionalParams extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getByLang operation. */
export type GetByLangResponse = MyDatabankResource[];

/** Optional parameters. */
export interface GetByTypeOptionalParams extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getByType operation. */
export type GetByTypeResponse = MyDatabankResource[];

/** Optional parameters. */
export interface GetDictionariesOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getDictionaries operation. */
export type GetDictionariesResponse = DictionaryResource[];

/** Optional parameters. */
export interface GetDictionaryByIdOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getDictionaryById operation. */
export type GetDictionaryByIdResponse = DictionaryPublication;

/** Optional parameters. */
export interface GetDictionaryNavByIdOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getDictionaryNavById operation. */
export type GetDictionaryNavByIdResponse = DocumentInfoResource[];

/** Optional parameters. */
export interface SearchDictionaryDefinitionTermAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  word?: string;
}

/** Contains response data for the searchDictionaryDefinitionTermAsync operation. */
export type SearchDictionaryDefinitionTermAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface GetNewsAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getNewsAsync operation. */
export type GetNewsAsyncResponse = EmphasizedPubResource[];

/** Optional parameters. */
export interface GetMostConsultedAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getMostConsultedAsync operation. */
export type GetMostConsultedAsyncResponse = EmphasizedPubResource[];

/** Optional parameters. */
export interface GetDoctrineLawDomainFieldsOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getDoctrineLawDomainFields operation. */
export type GetDoctrineLawDomainFieldsResponse = LawDomainFieldDto[];

/** Optional parameters. */
export interface GetDocumentTypeListOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getDocumentTypeList operation. */
export type GetDocumentTypeListResponse = DoctrineDocumentTypeDto[];

/** Optional parameters. */
export interface GetPublicationsAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  partnerCode?: string;
  documentType?: string;
}

/** Contains response data for the getPublicationsAsync operation. */
export type GetPublicationsAsyncResponse = PublicationResourcePagedList;

/** Optional parameters. */
export interface GetPublicationHierarchyAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  collectionCode?: string;
}

/** Contains response data for the getPublicationHierarchyAsync operation. */
export type GetPublicationHierarchyAsyncResponse = PublicationNavCollectionResource;

/** Optional parameters. */
export interface GetDoctrineDocumentOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  itemUrl?: string;
}

/** Contains response data for the getDoctrineDocument operation. */
export type GetDoctrineDocumentResponse = PublicationDetailsResource;

/** Optional parameters. */
export interface GetDocumentListAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  collection?: string;
  documentId?: string;
  title?: string;
  /** Array of Integer */
  lawDomainIds?: number[];
  filter?: string;
  visibleOnly?: boolean;
}

/** Contains response data for the getDocumentListAsync operation. */
export type GetDocumentListAsyncResponse = DocumentOptionResourcePagedList;

/** Optional parameters. */
export interface GetDocumentAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  doc?: string;
}

/** Contains response data for the getDocumentAsync operation. */
export type GetDocumentAsyncResponse = DocumentResource;

/** Optional parameters. */
export interface UpdateDocumentAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  doc?: string;
  body?: DocumentResourceForEdit;
}

/** Contains response data for the updateDocumentAsync operation. */
export type UpdateDocumentAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface CreateDocumentOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: DocumentResourceForCreate;
}

/** Contains response data for the createDocument operation. */
export type CreateDocumentResponse = ProblemDetails;

/** Optional parameters. */
export interface GetDocumentOptionalParams extends coreClient.OperationOptions {
  caijXToken?: string;
  doc?: string;
}

/** Contains response data for the getDocument operation. */
export type GetDocumentResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface DeleteDocumentOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the deleteDocument operation. */
export type DeleteDocumentResponse = ProblemDetails;

/** Optional parameters. */
export interface TriggerDownloadDocumentOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  doc?: string;
}

/** Contains response data for the triggerDownloadDocument operation. */
export type TriggerDownloadDocumentResponse = ProblemDetails;

/** Optional parameters. */
export interface GetDocumentMetadatasOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  doc?: string;
  documentType?: DocumentType;
}

/** Contains response data for the getDocumentMetadatas operation. */
export type GetDocumentMetadatasResponse = DocumentInfo;

/** Optional parameters. */
export interface GetDocumentTocOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  docId?: string;
}

/** Contains response data for the getDocumentToc operation. */
export type GetDocumentTocResponse = TocElement[];

/** Optional parameters. */
export interface GetDocumentCitedByOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  docId?: string;
  offset?: number;
  limit?: number;
}

/** Contains response data for the getDocumentCitedBy operation. */
export type GetDocumentCitedByResponse = CitedDocument[];

/** Optional parameters. */
export interface GetDocumentIdentifierOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  doc?: string;
}

/** Contains response data for the getDocumentIdentifier operation. */
export type GetDocumentIdentifierResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface ConvertDocumentIdsToDocUrlsOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  /** Array of PostContentSchemaItem */
  body?: string[];
}

/** Contains response data for the convertDocumentIdsToDocUrls operation. */
export type ConvertDocumentIdsToDocUrlsResponse = {
  [propertyName: string]: string;
};

/** Optional parameters. */
export interface GetDoorAccessProfilesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getDoorAccessProfilesAsync operation. */
export type GetDoorAccessProfilesAsyncResponse = DoorCardProfileDto[];

/** Optional parameters. */
export interface CreateDoorAccessProfileAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: DoorCardProfileDto;
}

/** Contains response data for the createDoorAccessProfileAsync operation. */
export type CreateDoorAccessProfileAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetDoorAccessProfileByIdAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getDoorAccessProfileByIdAsync operation. */
export type GetDoorAccessProfileByIdAsyncResponse = DoorCardProfileDto;

/** Optional parameters. */
export interface DeleteDoorAccessProfileAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteDoorAccessProfileAsync operation. */
export type DeleteDoorAccessProfileAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetDocumentForTextEditorOptionalParams
  extends coreClient.OperationOptions {
  doc?: string;
  version?: string;
}

/** Contains response data for the getDocumentForTextEditor operation. */
export type GetDocumentForTextEditorResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface SaveDocumentVersionOptionalParams
  extends coreClient.OperationOptions {
  body?: string;
  doc?: string;
}

/** Contains response data for the saveDocumentVersion operation. */
export type SaveDocumentVersionResponse = ProblemDetails;

/** Optional parameters. */
export interface PublishDocumentContentOptionalParams
  extends coreClient.OperationOptions {
  doc?: string;
  body?: PublishDocumentResource;
}

/** Contains response data for the publishDocumentContent operation. */
export type PublishDocumentContentResponse = ProblemDetails;

/** Optional parameters. */
export interface GetDocumentVersionHistoryOptionalParams
  extends coreClient.OperationOptions {
  doc?: string;
}

/** Contains response data for the getDocumentVersionHistory operation. */
export type GetDocumentVersionHistoryResponse = DocumentVersionResource[];

/** Optional parameters. */
export interface LockDocumentOptionalParams
  extends coreClient.OperationOptions {
  doc?: string;
}

/** Contains response data for the lockDocument operation. */
export type LockDocumentResponse = ProblemDetails;

/** Optional parameters. */
export interface UnlockDocumentOptionalParams
  extends coreClient.OperationOptions {
  doc?: string;
}

/** Contains response data for the unlockDocument operation. */
export type UnlockDocumentResponse = ProblemDetails;

/** Optional parameters. */
export interface GetLockStatusOptionalParams
  extends coreClient.OperationOptions {
  doc?: string;
}

/** Contains response data for the getLockStatus operation. */
export type GetLockStatusResponse = DocumentLockResource;

/** Optional parameters. */
export interface GetEmailTemplateListOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getEmailTemplateList operation. */
export type GetEmailTemplateListResponse = EmailTemplateDto[];

/** Optional parameters. */
export interface CreateEmailTemplateAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: EmailTemplateDto;
}

/** Contains response data for the createEmailTemplateAsync operation. */
export type CreateEmailTemplateAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetEmailTemplatePlaceholderTagsOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getEmailTemplatePlaceholderTags operation. */
export type GetEmailTemplatePlaceholderTagsResponse = {
  /** The parsed response body. */
  body: string[];
};

/** Optional parameters. */
export interface GetEmailTemplateAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getEmailTemplateAsync operation. */
export type GetEmailTemplateAsyncResponse = EmailTemplateDto;

/** Optional parameters. */
export interface UpdateEmailTemplateAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: EmailTemplateDto;
}

/** Contains response data for the updateEmailTemplateAsync operation. */
export type UpdateEmailTemplateAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteTemplateEmailAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteTemplateEmailAsync operation. */
export type DeleteTemplateEmailAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface TestEmailAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: EmailTemplateDto;
}

/** Contains response data for the testEmailAsync operation. */
export type TestEmailAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetEmployeesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getEmployeesAsync operation. */
export type GetEmployeesAsyncResponse = EmployeesResource[];

/** Optional parameters. */
export interface CreateEmployeeAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: EmployeeResourceForCreate;
}

/** Contains response data for the createEmployeeAsync operation. */
export type CreateEmployeeAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetEmployeeAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getEmployeeAsync operation. */
export type GetEmployeeAsyncResponse = EmployeesResource;

/** Optional parameters. */
export interface UpdateEmployeeAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: EmployeeResourceForEdit;
}

/** Contains response data for the updateEmployeeAsync operation. */
export type UpdateEmployeeAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteEmployeeAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteEmployeeAsync operation. */
export type DeleteEmployeeAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetMyEmployeeAccountOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getMyEmployeeAccount operation. */
export type GetMyEmployeeAccountResponse = EmployeeUser;

/** Optional parameters. */
export interface AddFileFormationAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the addFileFormationAsync operation. */
export type AddFileFormationAsyncResponse = UserContentItemsResource;

/** Optional parameters. */
export interface GetGeolocationOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getGeolocation operation. */
export type GetGeolocationResponse = { [propertyName: string]: string };

/** Optional parameters. */
export interface IsInLibraryOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the isInLibrary operation. */
export type IsInLibraryResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface GetLawsAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getLawsAsync operation. */
export type GetLawsAsyncResponse = LawResource[];

/** Optional parameters. */
export interface CreateLawAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: LawResourceForCreate;
}

/** Contains response data for the createLawAsync operation. */
export type CreateLawAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetLawAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getLawAsync operation. */
export type GetLawAsyncResponse = LawResource;

/** Optional parameters. */
export interface UpdateLawAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: LawResourceForEdit;
}

/** Contains response data for the updateLawAsync operation. */
export type UpdateLawAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteLawAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteLawAsync operation. */
export type DeleteLawAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetPublishedLawAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getPublishedLawAsync operation. */
export type GetPublishedLawAsyncResponse = MyLawResource[];

/** Optional parameters. */
export interface GetPublishedLawHomePageAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getPublishedLawHomePageAsync operation. */
export type GetPublishedLawHomePageAsyncResponse = MyLawResource;

/** Optional parameters. */
export interface GetFrequentlyConsultAsyncOptionalParams
  extends coreClient.OperationOptions {
  count?: number;
}

/** Contains response data for the getFrequentlyConsultAsync operation. */
export type GetFrequentlyConsultAsyncResponse = MostViewedLawsResource[];

/** Optional parameters. */
export interface DownloadEloisFormAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the downloadEloisFormAsync operation. */
export type DownloadEloisFormAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface DownloadModificationsTerminologiquesAsyncOptionalParams
  extends coreClient.OperationOptions {
  lang?: string;
}

/** Contains response data for the downloadModificationsTerminologiquesAsync operation. */
export type DownloadModificationsTerminologiquesAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface GetLawDomainFieldsAsyncOptionalParams
  extends coreClient.OperationOptions {
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  onlyRootNodes?: boolean;
  parentId?: number;
}

/** Contains response data for the getLawDomainFieldsAsync operation. */
export type GetLawDomainFieldsAsyncResponse = LawDomainFieldResourcePagedList;

/** Optional parameters. */
export interface CreateLawDomainFieldAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: LawDomainFieldResourceForCreate;
}

/** Contains response data for the createLawDomainFieldAsync operation. */
export type CreateLawDomainFieldAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetJurisListAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getJurisListAsync operation. */
export type GetJurisListAsyncResponse = MyLawDomainFieldResource[];

/** Optional parameters. */
export interface GetLawDomainFieldAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getLawDomainFieldAsync operation. */
export type GetLawDomainFieldAsyncResponse = LawDomainFieldResource;

/** Optional parameters. */
export interface UpdateLawDomainFieldAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: LawDomainFieldResourceForEdit;
}

/** Contains response data for the updateLawDomainFieldAsync operation. */
export type UpdateLawDomainFieldAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteLawDomainFieldAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteLawDomainFieldAsync operation. */
export type DeleteLawDomainFieldAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetLawDomainsAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getLawDomainsAsync operation. */
export type GetLawDomainsAsyncResponse = LawPracticeFieldResource[];

/** Optional parameters. */
export interface CreateLawDomainAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: LawPracticeFieldForCreate;
}

/** Contains response data for the createLawDomainAsync operation. */
export type CreateLawDomainAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetLawDomainAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getLawDomainAsync operation. */
export type GetLawDomainAsyncResponse = LawPracticeFieldResource;

/** Optional parameters. */
export interface UpdateLawDomainAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: LawPracticeFieldForEdit;
}

/** Contains response data for the updateLawDomainAsync operation. */
export type UpdateLawDomainAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteLawDomainAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteLawDomainAsync operation. */
export type DeleteLawDomainAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetUxpertiseGroupsOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getUxpertiseGroups operation. */
export type GetUxpertiseGroupsResponse = UxpertiseDto[];

/** Optional parameters. */
export interface CreateUxpertiseGroupAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: UxpertiseDto;
}

/** Contains response data for the createUxpertiseGroupAsync operation. */
export type CreateUxpertiseGroupAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteUxpertiseGroupAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteUxpertiseGroupAsync operation. */
export type DeleteUxpertiseGroupAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface AuthenticateUxpertiseOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: string;
}

/** Contains response data for the authenticateUxpertise operation. */
export type AuthenticateUxpertiseResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface GetLibrariesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getLibrariesAsync operation. */
export type GetLibrariesAsyncResponse = LibraryResource[];

/** Optional parameters. */
export interface CreateLibraryAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: LibraryResourceForCreate;
}

/** Contains response data for the createLibraryAsync operation. */
export type CreateLibraryAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetLibraryByIdAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getLibraryByIdAsync operation. */
export type GetLibraryByIdAsyncResponse = LibraryResource;

/** Optional parameters. */
export interface UpdateLibraryAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: LibraryResourceForEdit;
}

/** Contains response data for the updateLibraryAsync operation. */
export type UpdateLibraryAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteLibraryAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteLibraryAsync operation. */
export type DeleteLibraryAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface SynchroAllAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the synchroAllAsync operation. */
export type SynchroAllAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetLibraryJsonAsyncOptionalParams
  extends coreClient.OperationOptions {
  id?: string;
}

/** Contains response data for the getLibraryJsonAsync operation. */
export type GetLibraryJsonAsyncResponse = WordpressCaijLibraryRessource[];

/** Optional parameters. */
export interface UpdateEmailStatusAsyncOptionalParams
  extends coreClient.OperationOptions {
  /** Anything */
  body?: any;
}

/** Contains response data for the updateEmailStatusAsync operation. */
export type UpdateEmailStatusAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface MailingOptionalParams extends coreClient.OperationOptions {
  email?: string;
}

/** Contains response data for the mailing operation. */
export type MailingResponse = EmailSubscriptions;

/** Optional parameters. */
export interface SetLcapSubsOptionalParams extends coreClient.OperationOptions {
  body?: EmailSubscriptions;
}

/** Contains response data for the setLcapSubs operation. */
export type SetLcapSubsResponse = ProblemDetails;

/** Optional parameters. */
export interface GetMeOptionalParams extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getMe operation. */
export type GetMeResponse = MeResource;

/** Optional parameters. */
export interface SaveCustomerAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: PersonalDetailsResource;
}

/** Contains response data for the saveCustomerAsync operation. */
export type SaveCustomerAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface GetMeCardBalanceOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getMeCardBalance operation. */
export type GetMeCardBalanceResponse = {
  /** The parsed response body. */
  body: number;
};

/** Optional parameters. */
export interface MeLoggedOptionalParams extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the meLogged operation. */
export type MeLoggedResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface ChangePasswordAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: ChangePasswordResource;
}

/** Contains response data for the changePasswordAsync operation. */
export type ChangePasswordAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface UpdateEmailAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: string;
}

/** Contains response data for the updateEmailAsync operation. */
export type UpdateEmailAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface UpdateBirthdayAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: Date;
}

/** Contains response data for the updateBirthdayAsync operation. */
export type UpdateBirthdayAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface AddAddressAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: CustomerAddressDto;
}

/** Contains response data for the addAddressAsync operation. */
export type AddAddressAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface UpdateAddressAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: CustomerAddressDto;
}

/** Contains response data for the updateAddressAsync operation. */
export type UpdateAddressAsyncResponse = {
  /** The parsed response body. */
  body: number;
};

/** Optional parameters. */
export interface DeleteAddressAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the deleteAddressAsync operation. */
export type DeleteAddressAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface UpdatePhoneAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: PhoneResource;
}

/** Contains response data for the updatePhoneAsync operation. */
export type UpdatePhoneAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface ResendEmailValidationAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the resendEmailValidationAsync operation. */
export type ResendEmailValidationAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface CancelEmailChangeAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the cancelEmailChangeAsync operation. */
export type CancelEmailChangeAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface SendDocumentSpottingRequestAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: string;
}

/** Contains response data for the sendDocumentSpottingRequestAsync operation. */
export type SendDocumentSpottingRequestAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface GetMyPictureOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getMyPicture operation. */
export type GetMyPictureResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface UploadMyPictureAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the uploadMyPictureAsync operation. */
export type UploadMyPictureAsyncResponse = PictureResponseSuccess;

/** Optional parameters. */
export interface DeleteMyPictureAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the deleteMyPictureAsync operation. */
export type DeleteMyPictureAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface GetCardTransactionsAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  type?: string;
  year?: number;
  month?: number;
  minDate?: string;
  maxDate?: string;
}

/** Contains response data for the getCardTransactionsAsync operation. */
export type GetCardTransactionsAsyncResponse = MyCardTransactionsResource;

/** Optional parameters. */
export interface AddCardTransactionsNoteOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: NoteResource;
}

/** Contains response data for the addCardTransactionsNote operation. */
export type AddCardTransactionsNoteResponse = ProblemDetails;

/** Optional parameters. */
export interface RequestCardTransactionOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: CustomerAddressDto;
}

/** Contains response data for the requestCardTransaction operation. */
export type RequestCardTransactionResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface PutCardTransactionsNotesOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: string;
}

/** Contains response data for the putCardTransactionsNotes operation. */
export type PutCardTransactionsNotesResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteCardTransactionsNoteOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the deleteCardTransactionsNote operation. */
export type DeleteCardTransactionsNoteResponse = ProblemDetails;

/** Optional parameters. */
export interface CreateMeetingOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: MeetingResource;
}

/** Contains response data for the createMeeting operation. */
export type CreateMeetingResponse = WhereByResponse;

/** Optional parameters. */
export interface GetMessagesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getMessagesAsync operation. */
export type GetMessagesAsyncResponse = MessageResource[];

/** Optional parameters. */
export interface CreateMessageAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: MessageResourceForCreate;
}

/** Contains response data for the createMessageAsync operation. */
export type CreateMessageAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetMessageAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getMessageAsync operation. */
export type GetMessageAsyncResponse = MessageResource;

/** Optional parameters. */
export interface UpdateMessageAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: MessageResourceForEdit;
}

/** Contains response data for the updateMessageAsync operation. */
export type UpdateMessageAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteMessageAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteMessageAsync operation. */
export type DeleteMessageAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetActiveMessagesAsyncOptionalParams
  extends coreClient.OperationOptions {
  placeholder?: string;
}

/** Contains response data for the getActiveMessagesAsync operation. */
export type GetActiveMessagesAsyncResponse = MessageDto[];

/** Optional parameters. */
export interface GetContentMostViewedsAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getContentMostViewedsAsync operation. */
export type GetContentMostViewedsAsyncResponse = ContentMostViewedResource[];

/** Optional parameters. */
export interface UpdateContentMostViewedAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: boolean;
}

/** Contains response data for the updateContentMostViewedAsync operation. */
export type UpdateContentMostViewedAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteContentMostViewedAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteContentMostViewedAsync operation. */
export type DeleteContentMostViewedAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface UpdateMostViewedRankingAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: string;
}

/** Contains response data for the updateMostViewedRankingAsync operation. */
export type UpdateMostViewedRankingAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetUserHistoryAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  type?: number;
  filterByApplication?: string;
  /** Array of Get2ItemsItem */
  productFilters?: string[];
  /** Array of Integer */
  typeFilters?: number[];
  dateRangeSelected?: number;
  startDate?: string;
  endDate?: string;
}

/** Contains response data for the getUserHistoryAsync operation. */
export type GetUserHistoryAsyncResponse = UserHistoryResourcePagedList;

/** Optional parameters. */
export interface CreateHistoryAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: UserHistoryResourceForCreate;
}

/** Contains response data for the createHistoryAsync operation. */
export type CreateHistoryAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteHistoryItemsAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: string;
}

/** Contains response data for the deleteHistoryItemsAsync operation. */
export type DeleteHistoryItemsAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetUserHistoryByIdAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getUserHistoryByIdAsync operation. */
export type GetUserHistoryByIdAsyncResponse = UserHistoryResource;

/** Optional parameters. */
export interface DeleteHistoryItemByIdAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the deleteHistoryItemByIdAsync operation. */
export type DeleteHistoryItemByIdAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetIsUserHistoryEnabledAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getIsUserHistoryEnabledAsync operation. */
export type GetIsUserHistoryEnabledAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface UpdateUserHistoryActiveStatusAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: boolean;
}

/** Contains response data for the updateUserHistoryActiveStatusAsync operation. */
export type UpdateUserHistoryActiveStatusAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetConsultedDocAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  app?: string;
  /** Array of Get1ItemsItem */
  urlList?: string[];
}

/** Contains response data for the getConsultedDocAsync operation. */
export type GetConsultedDocAsyncResponse = { [propertyName: string]: Date };

/** Optional parameters. */
export interface GetMyLibraryFeesAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getMyLibraryFeesAsync operation. */
export type GetMyLibraryFeesAsyncResponse = FeesResponse;

/** Optional parameters. */
export interface GetMyLibraryHoldsAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getMyLibraryHoldsAsync operation. */
export type GetMyLibraryHoldsAsyncResponse = Hold[];

/** Optional parameters. */
export interface GetMyLibraryHoldsCountAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getMyLibraryHoldsCountAsync operation. */
export type GetMyLibraryHoldsCountAsyncResponse = {
  /** The parsed response body. */
  body: number;
};

/** Optional parameters. */
export interface CreateLibraryHoldsWithCallnumbersAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: HoldRequestOld;
}

/** Contains response data for the createLibraryHoldsWithCallnumbersAsync operation. */
export type CreateLibraryHoldsWithCallnumbersAsyncResponse = HoldRequestResponseOld;

/** Optional parameters. */
export interface CreateLibraryHoldAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: HoldRequest;
}

/** Contains response data for the createLibraryHoldAsync operation. */
export type CreateLibraryHoldAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface CancelMyLibraryHoldAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the cancelMyLibraryHoldAsync operation. */
export type CancelMyLibraryHoldAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetMyLibraryCheckoutsAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getMyLibraryCheckoutsAsync operation. */
export type GetMyLibraryCheckoutsAsyncResponse = Charge[];

/** Optional parameters. */
export interface GetMyLibraryCheckoutsCountAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getMyLibraryCheckoutsCountAsync operation. */
export type GetMyLibraryCheckoutsCountAsyncResponse = {
  /** The parsed response body. */
  body: number;
};

/** Optional parameters. */
export interface RenewMyLibraryCheckoutAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the renewMyLibraryCheckoutAsync operation. */
export type RenewMyLibraryCheckoutAsyncResponse = Charge;

/** Optional parameters. */
export interface GetMyLibraryCheckoutsHistoryAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getMyLibraryCheckoutsHistoryAsync operation. */
export type GetMyLibraryCheckoutsHistoryAsyncResponse = ChargeHistory[];

/** Optional parameters. */
export interface GetCartItemCountOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getCartItemCount operation. */
export type GetCartItemCountResponse = {
  /** The parsed response body. */
  body: number;
};

/** Optional parameters. */
export interface GetCartOptionalParams extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getCart operation. */
export type GetCartResponse = LibraryCartItemDto[];

/** Optional parameters. */
export interface DeleteCartItemsByTypeOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  type?: number;
}

/** Contains response data for the deleteCartItemsByType operation. */
export type DeleteCartItemsByTypeResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface ProcessCartOptionalParams extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: ProcessCartParameters;
}

/** Contains response data for the processCart operation. */
export type ProcessCartResponse = LibraryCartProcessingResult;

/** Optional parameters. */
export interface AddCartItemOptionalParams extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: LibraryCartItemDto;
}

/** Contains response data for the addCartItem operation. */
export type AddCartItemResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface UpdateCartItemOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: LibraryCartItemDto;
}

/** Contains response data for the updateCartItem operation. */
export type UpdateCartItemResponse = LibraryCartItemDto;

/** Optional parameters. */
export interface DeleteCartItemOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the deleteCartItem operation. */
export type DeleteCartItemResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface GetOrderHistoryOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getOrderHistory operation. */
export type GetOrderHistoryResponse = LibraryCartItemDto[];

/** Optional parameters. */
export interface GetLibrariesPrintersOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  lang?: Lang;
}

/** Contains response data for the getLibrariesPrinters operation. */
export type GetLibrariesPrintersResponse = LibraryPrinterResource[];

/** Optional parameters. */
export interface GetNotificationsOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  notificationTypeId?: number;
  excludeRead?: boolean;
  sortedBy?: string;
  sortedDesc?: boolean;
  sortedBy1?: string;
}

/** Contains response data for the getNotifications operation. */
export type GetNotificationsResponse = UserNotificationResourcePagedList;

/** Optional parameters. */
export interface GetNotificationCountOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  notificationTypeId?: number;
}

/** Contains response data for the getNotificationCount operation. */
export type GetNotificationCountResponse = {
  /** The parsed response body. */
  body: number;
};

/** Optional parameters. */
export interface GetNotificationCountUnreadOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  notificationTypeId?: number;
}

/** Contains response data for the getNotificationCountUnread operation. */
export type GetNotificationCountUnreadResponse = {
  /** The parsed response body. */
  body: number;
};

/** Optional parameters. */
export interface GetNotificationTypesOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getNotificationTypes operation. */
export type GetNotificationTypesResponse = NotificationTypeResource[];

/** Optional parameters. */
export interface MarkAllUserNotificationsAsReadOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  notificationTypeId?: number;
}

/** Contains response data for the markAllUserNotificationsAsRead operation. */
export type MarkAllUserNotificationsAsReadResponse = ProblemDetails;

/** Optional parameters. */
export interface MarkUserNotificationAsReadOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the markUserNotificationAsRead operation. */
export type MarkUserNotificationAsReadResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteNotificationOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the deleteNotification operation. */
export type DeleteNotificationResponse = ProblemDetails;

/** Optional parameters. */
export interface GetPartnersAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  onlyHomePageItems?: boolean;
  searchTerm?: string;
  documentType?: string;
}

/** Contains response data for the getPartnersAsync operation. */
export type GetPartnersAsyncResponse = SimplePartnerResource[];

/** Optional parameters. */
export interface ListPartnersAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the listPartnersAsync operation. */
export type ListPartnersAsyncResponse = PartnerResource[];

/** Optional parameters. */
export interface CreatePartnerAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: PartnerResourceForCreate;
}

/** Contains response data for the createPartnerAsync operation. */
export type CreatePartnerAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetPartnerAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getPartnerAsync operation. */
export type GetPartnerAsyncResponse = PartnerResource;

/** Optional parameters. */
export interface UpdatePartnerAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: PartnerResourceForEdit;
}

/** Contains response data for the updatePartnerAsync operation. */
export type UpdatePartnerAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeletePartnerAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deletePartnerAsync operation. */
export type DeletePartnerAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetMessagePlaceholdersAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getMessagePlaceholdersAsync operation. */
export type GetMessagePlaceholdersAsyncResponse = MessagePlaceholderResource[];

/** Optional parameters. */
export interface CreateMessagePlaceholderAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: MessagePlaceholderResourceForCreate;
}

/** Contains response data for the createMessagePlaceholderAsync operation. */
export type CreateMessagePlaceholderAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetMessagePlaceholderAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getMessagePlaceholderAsync operation. */
export type GetMessagePlaceholderAsyncResponse = MessagePlaceholderResource;

/** Optional parameters. */
export interface UpdateMessagePlaceholderAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: MessagePlaceholderResourceForEdit;
}

/** Contains response data for the updateMessagePlaceholderAsync operation. */
export type UpdateMessagePlaceholderAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteMessagePlaceholderAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteMessagePlaceholderAsync operation. */
export type DeleteMessagePlaceholderAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetProductsAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getProductsAsync operation. */
export type GetProductsAsyncResponse = ProductResource[];

/** Optional parameters. */
export interface CreateProductAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: ProductResourceForCreate;
}

/** Contains response data for the createProductAsync operation. */
export type CreateProductAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetProductAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getProductAsync operation. */
export type GetProductAsyncResponse = ProductResource;

/** Optional parameters. */
export interface UpdateProductAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: ProductResourceForEdit;
}

/** Contains response data for the updateProductAsync operation. */
export type UpdateProductAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteProductAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteProductAsync operation. */
export type DeleteProductAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetProductSubscriptionsOptionalParams
  extends coreClient.OperationOptions {
  productCode?: string;
}

/** Contains response data for the getProductSubscriptions operation. */
export type GetProductSubscriptionsResponse = {
  [propertyName: string]: number[];
};

/** Optional parameters. */
export interface GetProxiesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getProxiesAsync operation. */
export type GetProxiesAsyncResponse = ProxyDto[];

/** Optional parameters. */
export interface GetContentPublicationsAsyncOptionalParams
  extends coreClient.OperationOptions {
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  collection?: string;
  documentId?: string;
  title?: string;
  /** Array of Integer */
  lawDomainIds?: number[];
  filter?: string;
  visibleOnly?: boolean;
}

/** Contains response data for the getContentPublicationsAsync operation. */
export type GetContentPublicationsAsyncResponse = ContentPublicationsResourcePagedList;

/** Optional parameters. */
export interface GetContentPublicationByIdAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getContentPublicationByIdAsync operation. */
export type GetContentPublicationByIdAsyncResponse = ContentPublicationsResource;

/** Optional parameters. */
export interface UpdateContentPublicationAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: ContentPublicationsResourceForEdit;
}

/** Contains response data for the updateContentPublicationAsync operation. */
export type UpdateContentPublicationAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteContentPublicationAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteContentPublicationAsync operation. */
export type DeleteContentPublicationAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetReperagesAsyncOptionalParams
  extends coreClient.OperationOptions {
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  noCaij?: string;
  archived?: boolean;
}

/** Contains response data for the getReperagesAsync operation. */
export type GetReperagesAsyncResponse = ReperageResourcePagedList;

/** Optional parameters. */
export interface CreateReperageAsycOptionalParams
  extends coreClient.OperationOptions {
  body?: ReperageResourceForCreate;
}

/** Contains response data for the createReperageAsyc operation. */
export type CreateReperageAsycResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface GetReperageAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getReperageAsync operation. */
export type GetReperageAsyncResponse = ReperageResource;

/** Optional parameters. */
export interface UpdateReperageAsycOptionalParams
  extends coreClient.OperationOptions {
  body?: ReperageResourceForEdit;
}

/** Contains response data for the updateReperageAsyc operation. */
export type UpdateReperageAsycResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface DeleteReperageAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteReperageAsync operation. */
export type DeleteReperageAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface AddFileReperageAsyncOptionalParams
  extends coreClient.OperationOptions {
  file?: coreRestPipeline.RequestBodyType;
}

/** Contains response data for the addFileReperageAsync operation. */
export type AddFileReperageAsyncResponse = UserContentItemsResource;

/** Optional parameters. */
export interface EditContentItemReperageAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: UserContentItemsResourceForEdit;
}

/** Contains response data for the editContentItemReperageAsync operation. */
export type EditContentItemReperageAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetDocumentFileAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getDocumentFileAsync operation. */
export type GetDocumentFileAsyncResponse = RequestedDocumentFile;

/** Optional parameters. */
export interface SendReperageAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: ReperageResourceForEdit;
}

/** Contains response data for the sendReperageAsync operation. */
export type SendReperageAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface CopyReperageAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the copyReperageAsync operation. */
export type CopyReperageAsyncResponse = {
  /** The parsed response body. */
  body: number;
};

/** Optional parameters. */
export interface DeleteContentItemReperageAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteContentItemReperageAsync operation. */
export type DeleteContentItemReperageAsyncResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface GetAllCustomersReperagesAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  noCaij?: string;
  archived?: boolean;
}

/** Contains response data for the getAllCustomersReperagesAsync operation. */
export type GetAllCustomersReperagesAsyncResponse = ReperageResourcePagedList;

/** Optional parameters. */
export interface AddDocumentToCustomerReperagesAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: UserContentItemsResourceForCreate;
}

/** Contains response data for the addDocumentToCustomerReperagesAsync operation. */
export type AddDocumentToCustomerReperagesAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface AddMeFileReperageAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the addMeFileReperageAsync operation. */
export type AddMeFileReperageAsyncResponse = UserContentItemsResource;

/** Optional parameters. */
export interface GetResourcesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getResourcesAsync operation. */
export type GetResourcesAsyncResponse = Resource[];

/** Optional parameters. */
export interface CreateResourceAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: ResourceForCreate;
}

/** Contains response data for the createResourceAsync operation. */
export type CreateResourceAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetResourceAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getResourceAsync operation. */
export type GetResourceAsyncResponse = Resource;

/** Optional parameters. */
export interface UpdateResourceAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: ResourceForEdit;
}

/** Contains response data for the updateResourceAsync operation. */
export type UpdateResourceAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteResourceAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteResourceAsync operation. */
export type DeleteResourceAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface SanityCheckAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the sanityCheckAsync operation. */
export type SanityCheckAsyncResponse = { [propertyName: string]: string };

/** Optional parameters. */
export interface GetSearchTokenAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getSearchTokenAsync operation. */
export type GetSearchTokenAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface GetCloudSearchTokenAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getCloudSearchTokenAsync operation. */
export type GetCloudSearchTokenAsyncResponse = {
  /** The parsed response body. */
  body: string;
};

/** Optional parameters. */
export interface GetSearchesAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  collection?: DocumentType;
  q?: string;
  aq?: string;
  f?: string;
  i?: number;
  n?: number;
  sortCriteria?: string;
  sortField?: string;
}

/** Contains response data for the getSearchesAsync operation. */
export type GetSearchesAsyncResponse = SearchResults;

/** Optional parameters. */
export interface PostSearchAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: QueryParameters;
}

/** Contains response data for the postSearchAsync operation. */
export type PostSearchAsyncResponse = SearchResults;

/** Optional parameters. */
export interface GetFacetSearchValuesAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  collection?: DocumentType;
  field?: string;
  sort?: string;
  maxResults?: number;
  term?: string;
}

/** Contains response data for the getFacetSearchValuesAsync operation. */
export type GetFacetSearchValuesAsyncResponse = FacetSearchValuesResult;

/** Optional parameters. */
export interface PostFacetSearchValuesAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: FacetSearchQueryParameters;
}

/** Contains response data for the postFacetSearchValuesAsync operation. */
export type PostFacetSearchValuesAsyncResponse = FacetSearchValuesResult;

/** Optional parameters. */
export interface GetUserVeilleAlertsAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  archived?: boolean;
  sortedDesc?: boolean;
  filterByName?: string;
}

/** Contains response data for the getUserVeilleAlertsAsync operation. */
export type GetUserVeilleAlertsAsyncResponse = UserContentItemsResourcePagedList;

/** Optional parameters. */
export interface GetUserVeilleAlertByIdAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getUserVeilleAlertByIdAsync operation. */
export type GetUserVeilleAlertByIdAsyncResponse = UserContentItemsResource;

/** Optional parameters. */
export interface GetUserVeilleAlertItemsAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  archived?: boolean;
  sortedDesc?: boolean;
  filterByName?: string;
}

/** Contains response data for the getUserVeilleAlertItemsAsync operation. */
export type GetUserVeilleAlertItemsAsyncResponse = UserAlertItemsPagedList;

/** Optional parameters. */
export interface DeleteAllUserAlertItemsOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the deleteAllUserAlertItems operation. */
export type DeleteAllUserAlertItemsResponse = ProblemDetails;

/** Optional parameters. */
export interface MarkUserAlertItemAsReadOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the markUserAlertItemAsRead operation. */
export type MarkUserAlertItemAsReadResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteUserAlertItemOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the deleteUserAlertItem operation. */
export type DeleteUserAlertItemResponse = ProblemDetails;

/** Optional parameters. */
export interface MarkAllUserAlertItemsAsReadOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the markAllUserAlertItemsAsRead operation. */
export type MarkAllUserAlertItemsAsReadResponse = ProblemDetails;

/** Optional parameters. */
export interface GetSearchEngineSourceListOptionalParams
  extends coreClient.OperationOptions {
  collection?: string;
}

/** Contains response data for the getSearchEngineSourceList operation. */
export type GetSearchEngineSourceListResponse = CoveoSourceDto[];

/** Optional parameters. */
export interface CreateSearchEngineSourceAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: CoveoSourceDto;
}

/** Contains response data for the createSearchEngineSourceAsync operation. */
export type CreateSearchEngineSourceAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface UpdateSearchEngineSourceAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: CoveoSourceDto;
}

/** Contains response data for the updateSearchEngineSourceAsync operation. */
export type UpdateSearchEngineSourceAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteSearchEngineSourceAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteSearchEngineSourceAsync operation. */
export type DeleteSearchEngineSourceAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetSearchEngineTaskHistoryListOptionalParams
  extends coreClient.OperationOptions {
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  status?: string;
  email?: string;
  startDate?: string;
  endDate?: string;
  coveoSourceId?: number;
  sourceName?: string;
  sourceCollection?: string;
  coveoIdentifier?: string;
  task?: string;
}

/** Contains response data for the getSearchEngineTaskHistoryList operation. */
export type GetSearchEngineTaskHistoryListResponse = CoveoTaskHistoryDtoPagedList;

/** Optional parameters. */
export interface GetCoveoLogsListOptionalParams
  extends coreClient.OperationOptions {
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
}

/** Contains response data for the getCoveoLogsList operation. */
export type GetCoveoLogsListResponse = CoveoLogsPagedList;

/** Optional parameters. */
export interface CreateSearchEngineTaskAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: CoveoTaskDto;
}

/** Contains response data for the createSearchEngineTaskAsync operation. */
export type CreateSearchEngineTaskAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface UpdateSearchEngineTaskAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: CoveoTaskDto;
}

/** Contains response data for the updateSearchEngineTaskAsync operation. */
export type UpdateSearchEngineTaskAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetLibraryServicesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getLibraryServicesAsync operation. */
export type GetLibraryServicesAsyncResponse = LibraryServiceResource[];

/** Optional parameters. */
export interface CreateLibraryServiceAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: LibraryServiceResourceForCreate;
}

/** Contains response data for the createLibraryServiceAsync operation. */
export type CreateLibraryServiceAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetLibraryServiceAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getLibraryServiceAsync operation. */
export type GetLibraryServiceAsyncResponse = LibraryServiceResource;

/** Optional parameters. */
export interface UpdateLibraryServiceAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: LibraryServiceResourceForEdit;
}

/** Contains response data for the updateLibraryServiceAsync operation. */
export type UpdateLibraryServiceAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteLibraryServiceAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteLibraryServiceAsync operation. */
export type DeleteLibraryServiceAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetSpecialFilesDocumentsOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getSpecialFilesDocuments operation. */
export type GetSpecialFilesDocumentsResponse = PublicDocumentOptionResource[];

/** Optional parameters. */
export interface HeadStatusOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the headStatus operation. */
export type HeadStatusResponse = ProblemDetails;

/** Optional parameters. */
export interface GetStatusOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the getStatus operation. */
export type GetStatusResponse = ProblemDetails;

/** Optional parameters. */
export interface GetSubscriptionsAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getSubscriptionsAsync operation. */
export type GetSubscriptionsAsyncResponse = SubscriptionResource[];

/** Optional parameters. */
export interface CreateSubscriptionAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: SubscriptionResourceForCreate;
}

/** Contains response data for the createSubscriptionAsync operation. */
export type CreateSubscriptionAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetSubscriptionAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getSubscriptionAsync operation. */
export type GetSubscriptionAsyncResponse = SubscriptionResource;

/** Optional parameters. */
export interface UpdateSubscriptionAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: SubscriptionResourceForEdit;
}

/** Contains response data for the updateSubscriptionAsync operation. */
export type UpdateSubscriptionAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteSubscriptionAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteSubscriptionAsync operation. */
export type DeleteSubscriptionAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetAllSubscriptionGroupsAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getAllSubscriptionGroupsAsync operation. */
export type GetAllSubscriptionGroupsAsyncResponse = SubscriptionGroupResource[];

/** Optional parameters. */
export interface GetSubscriptionGroupsAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getSubscriptionGroupsAsync operation. */
export type GetSubscriptionGroupsAsyncResponse = SubscriptionGroupResource[];

/** Optional parameters. */
export interface CreateSubscriptionGroupAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: SubscriptionGroupResourceForEdit;
}

/** Contains response data for the createSubscriptionGroupAsync operation. */
export type CreateSubscriptionGroupAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetSubscriptionGroupAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getSubscriptionGroupAsync operation. */
export type GetSubscriptionGroupAsyncResponse = SubscriptionGroupResource;

/** Optional parameters. */
export interface UpdateSubscriptionGroupAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: SubscriptionGroupResourceForEdit;
}

/** Contains response data for the updateSubscriptionGroupAsync operation. */
export type UpdateSubscriptionGroupAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteSubscriptionGroupAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteSubscriptionGroupAsync operation. */
export type DeleteSubscriptionGroupAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetSymphonyLibrariesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getSymphonyLibrariesAsync operation. */
export type GetSymphonyLibrariesAsyncResponse = WfLibraryDto[];

/** Optional parameters. */
export interface CreateSymphonyLibraryAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: WfLibraryDto;
}

/** Contains response data for the createSymphonyLibraryAsync operation. */
export type CreateSymphonyLibraryAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetActiveLibrariesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getActiveLibrariesAsync operation. */
export type GetActiveLibrariesAsyncResponse = WfLibraryDto[];

/** Optional parameters. */
export interface GetSymphonyLibraryByIdOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getSymphonyLibraryById operation. */
export type GetSymphonyLibraryByIdResponse = WfLibraryDto;

/** Optional parameters. */
export interface DeleteSymphonyLibraryAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteSymphonyLibraryAsync operation. */
export type DeleteSymphonyLibraryAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetSymphonyProfilesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getSymphonyProfilesAsync operation. */
export type GetSymphonyProfilesAsyncResponse = WfProfileDto[];

/** Optional parameters. */
export interface CreateSymphonyProfileAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: WfProfileDto;
}

/** Contains response data for the createSymphonyProfileAsync operation. */
export type CreateSymphonyProfileAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetActiveProfilesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getActiveProfilesAsync operation. */
export type GetActiveProfilesAsyncResponse = WfProfileDto[];

/** Optional parameters. */
export interface GetSymphonyProfileByIdOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getSymphonyProfileById operation. */
export type GetSymphonyProfileByIdResponse = WfProfileDto;

/** Optional parameters. */
export interface DeleteSymphonyProfileAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteSymphonyProfileAsync operation. */
export type DeleteSymphonyProfileAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetTopoDocumentsOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  domain?: number;
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  searchTerm?: string;
}

/** Contains response data for the getTopoDocuments operation. */
export type GetTopoDocumentsResponse = DocumentOptionLawDomainResourcePagedList;

/** Optional parameters. */
export interface GetTopoLawDomainNavigationAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getTopoLawDomainNavigationAsync operation. */
export type GetTopoLawDomainNavigationAsyncResponse = TopoLawDomainNavigationResource[];

/** Optional parameters. */
export interface GetTopoLawDomainByIdOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getTopoLawDomainById operation. */
export type GetTopoLawDomainByIdResponse = TopoLawDomainNavigationResource;

/** Optional parameters. */
export interface GetTopoLawSubDomainNavigationAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getTopoLawSubDomainNavigationAsync operation. */
export type GetTopoLawSubDomainNavigationAsyncResponse = TopoLawDomainNavigationResource[];

/** Optional parameters. */
export interface GetTopoLatestAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getTopoLatestAsync operation. */
export type GetTopoLatestAsyncResponse = DocumentInfoResource[];

/** Optional parameters. */
export interface GetIsPaysafeServiceAvailableOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getIsPaysafeServiceAvailable operation. */
export type GetIsPaysafeServiceAvailableResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface MakeCreditCardTransactionOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  body?: TransactionDto;
}

/** Contains response data for the makeCreditCardTransaction operation. */
export type MakeCreditCardTransactionResponse = TransactionResponse;

/** Optional parameters. */
export interface GetMyInvoicesAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  minDate?: string;
  maxDate?: string;
  selectedTypes?: string;
  sortAsc?: boolean;
}

/** Contains response data for the getMyInvoicesAsync operation. */
export type GetMyInvoicesAsyncResponse = InvoiceInfoPagedList;

/** Optional parameters. */
export interface GetMyInvoiceAsyncOptionalParams
  extends coreClient.OperationOptions {
  caijXToken?: string;
}

/** Contains response data for the getMyInvoiceAsync operation. */
export type GetMyInvoiceAsyncResponse = InvoiceFileDto;

/** Optional parameters. */
export interface GetTranslationsAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getTranslationsAsync operation. */
export type GetTranslationsAsyncResponse = TranslationResource[];

/** Optional parameters. */
export interface CreateTranslationAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: TranslationResourceForCreate;
}

/** Contains response data for the createTranslationAsync operation. */
export type CreateTranslationAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetPagedTranslationsAsyncOptionalParams
  extends coreClient.OperationOptions {
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
}

/** Contains response data for the getPagedTranslationsAsync operation. */
export type GetPagedTranslationsAsyncResponse = TranslationResourcePagedList;

/** Optional parameters. */
export interface GetTranslationAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getTranslationAsync operation. */
export type GetTranslationAsyncResponse = TranslationResource;

/** Optional parameters. */
export interface UpdateTranslationAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: TranslationResourceForEdit;
}

/** Contains response data for the updateTranslationAsync operation. */
export type UpdateTranslationAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteTranslationAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteTranslationAsync operation. */
export type DeleteTranslationAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetTribunalsListOptionalParams
  extends coreClient.OperationOptions {
  lang?: string;
  jurisdiction?: string;
  sortedBy?: string;
  sortOptions?: string;
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
  tribunalType?: string;
}

/** Contains response data for the getTribunalsList operation. */
export type GetTribunalsListResponse = TribunalResourcePagedList;

/** Optional parameters. */
export interface CreateTribunalAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: TribunalResourceForCreate;
}

/** Contains response data for the createTribunalAsync operation. */
export type CreateTribunalAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetTribunalCodeAsyncOptionalParams
  extends coreClient.OperationOptions {
  code?: string;
}

/** Contains response data for the getTribunalCodeAsync operation. */
export type GetTribunalCodeAsyncResponse = { [propertyName: string]: number };

/** Optional parameters. */
export interface GetTribunalCodesOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getTribunalCodes operation. */
export type GetTribunalCodesResponse = { [propertyName: string]: number };

/** Optional parameters. */
export interface GetTribunalByCodeOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getTribunalByCode operation. */
export type GetTribunalByCodeResponse = TribunalResource;

/** Optional parameters. */
export interface GetTribunalLabelsOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getTribunalLabels operation. */
export type GetTribunalLabelsResponse = { [propertyName: string]: string };

/** Optional parameters. */
export interface GetTribunalByIdOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getTribunalById operation. */
export type GetTribunalByIdResponse = TribunalResource;

/** Optional parameters. */
export interface UpdateTribunalAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: TribunalResourceForEdit;
}

/** Contains response data for the updateTribunalAsync operation. */
export type UpdateTribunalAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteTribunalAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteTribunalAsync operation. */
export type DeleteTribunalAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetTribunalCodesNotAssignedAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getTribunalCodesNotAssignedAsync operation. */
export type GetTribunalCodesNotAssignedAsyncResponse = TribunalCodesResource[];

/** Optional parameters. */
export interface GetTribunalTypesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getTribunalTypesAsync operation. */
export type GetTribunalTypesAsyncResponse = TribunalTypeResource[];

/** Optional parameters. */
export interface CreateTribunalTypeAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: TribunalTypeResourceForCreate;
}

/** Contains response data for the createTribunalTypeAsync operation. */
export type CreateTribunalTypeAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetTribunalTypeAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getTribunalTypeAsync operation. */
export type GetTribunalTypeAsyncResponse = TribunalTypeResource;

/** Optional parameters. */
export interface UpdateTribunalTypeAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: TribunalTypeResourceForEdit;
}

/** Contains response data for the updateTribunalTypeAsync operation. */
export type UpdateTribunalTypeAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteTribunalTypeAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteTribunalTypeAsync operation. */
export type DeleteTribunalTypeAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetLibraryTypesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getLibraryTypesAsync operation. */
export type GetLibraryTypesAsyncResponse = LibraryTypeResource[];

/** Optional parameters. */
export interface CreateLibraryTypeAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: LibraryTypeResourceForCreate;
}

/** Contains response data for the createLibraryTypeAsync operation. */
export type CreateLibraryTypeAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetLibraryTypeAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getLibraryTypeAsync operation. */
export type GetLibraryTypeAsyncResponse = LibraryTypeResource;

/** Optional parameters. */
export interface UpdateLibraryTypeAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: LibraryTypeResourceForEdit;
}

/** Contains response data for the updateLibraryTypeAsync operation. */
export type UpdateLibraryTypeAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteLibraryTypeAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteLibraryTypeAsync operation. */
export type DeleteLibraryTypeAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetDocumentTypesAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getDocumentTypesAsync operation. */
export type GetDocumentTypesAsyncResponse = DocumentTypeResource[];

/** Optional parameters. */
export interface CreateDocumentTypeAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: DocumentTypeResourceForCreate;
}

/** Contains response data for the createDocumentTypeAsync operation. */
export type CreateDocumentTypeAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetDocumentTypeAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getDocumentTypeAsync operation. */
export type GetDocumentTypeAsyncResponse = DocumentTypeResource;

/** Optional parameters. */
export interface UpdateDocumentTypeAsyncOptionalParams
  extends coreClient.OperationOptions {
  body?: DocumentTypeResourceForEdit;
}

/** Contains response data for the updateDocumentTypeAsync operation. */
export type UpdateDocumentTypeAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface DeleteDocumentTypeAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the deleteDocumentTypeAsync operation. */
export type DeleteDocumentTypeAsyncResponse = ProblemDetails;

/** Optional parameters. */
export interface GetVersionAsyncOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the getVersionAsync operation. */
export type GetVersionAsyncResponse = Version;

/** Optional parameters. */
export interface CaijAPIOptionalParams extends coreClient.ServiceClientOptions {
  /** Overrides client endpoint. */
  endpoint?: string;
}
